import React from "react";
import Slider from "react-animated-slider";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

import "react-animated-slider/build/horizontal.css";
import "../styles/slider-animations.css";

//Images
import Sourcing from "./Sourcing";

//#f3763c

const Services = ({ autoPlay, infinite, title, slides, bkSize, borderColor }) => {
  return (
    <>
      <div className="p-5 container text-center">
        <h1
          className="text-left"
          style={{
            fontSize: "4rem",
            borderBottom: `2px solid ${borderColor}`,
            padding: "4rem 0 0",
            marginBottom: "2rem",
          }}
        >
          Take Advantage of{" "}
          <span
            className="bold text-uppercase"
            style={{ textShadow: "-4px 0 3px slategray", fontSize: "5rem" }}
          >
            Our
          </span>{" "}
          Superior
          <br />
        </h1>
        <h1 className="text-right">
          <span
            style={{
              textShadow: "-4px 0 3px slategray",
              fontWeight: "600",
              textTransform: "uppercase",
              fontSize: "6rem",
            }}
          >
            {title}
          </span>
        </h1>
        <Slider autoplay={autoPlay} infinite={infinite}>
          {slides.map((slide, i) => (
            <div
              key={i}
              style={{
                background: `url(${slide.image}) no-repeat center center`,
                backgroundSize: bkSize,
              }}
              className="slider-content"
            >
              <div
                style={{
                  background: `rgba(255,255,255,0.95)`,
                  width: `80%`,
                  margin: `3rem auto`,
                  borderRadius: `20px`,
                  padding: "4rem",
                  boxShadow: "0 0 10px black",
                }}
              >
                <i className={`fas ${slide.icon} fa-4x`}></i>
                <h1
                  style={{
                    fontSize: "4rem",
                    borderBottom: "1px solid slategray",
                    fontWeight: "400",
                  }}
                >
                  {slide.service}
                </h1>
                <p
                  style={{
                    fontSize: "2rem",
                    fontWeight: "200",
                    color: "black",
                  }}
                >
                  {slide.description}
                </p>
                <Link
                  role="button"
                  to="/contact"
                  className="btn btn_orange link"
                >
                  Click Here to Chat Now!
                </Link>
                <span style={{ fontSize: "1.2rem" }} className="link">
                  <br />
                  or <Link to="/">Back to Home</Link>
                </span>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      {/* Action Item */}
      <div className="chatBg text-center p-3">
        <h2
          style={{
            fontWeight: "600",
            color: "white",
            textShadow: "-5px 0 4px rgba(0,0,0,0.5",
          }}
        >
          Interested in chatting with us?
        </h2>
        <button className="btn btn-primary text-uppercase" id="myChat">
          Start Here!
        </button>
      </div>
    </>
  );
};

export default Services;
