import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    FormGroup,
    Input,
    Button,
    Jumbotron,
    Spinner,
} from "reactstrap";

import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

import axios from "axios";

import united from "../styles/united.module.css";

const United = () => {
    const [success, setSuccess] = useState(false);
    const [formMessage, setFormMessage] = useState({});

    // form schema
    const schema = Yup.object({
        deviceIMEI: Yup.string()
            .required("Required")
            .trim()
            .length(15, "IMEI must be 15 characters in length."),
        phoneNumber: Yup.string()
            .required("Required")
            .length(10, `Phone Number must be 10 characters in length`)
            .trim(),
    });
    useEffect(() => {
        const script = document.createElement("script");
        script.src =
            "https://static.zdassets.com/ekr/snippet.js?key=56d6b9ee-ed39-4797-8da6-56c090ded69e";
        script.id = "ze-snippet";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div>
            <h1>United Airlines Zendesk Dev</h1>
            {/*<Formik*/}
            {/*    initialValues={{*/}
            {/*        deviceIMEI: "",*/}
            {/*        phoneNumber: "",*/}
            {/*    }}*/}
            {/*    validationSchema={schema}*/}
            {/*    onSubmit={(values) => {*/}
            {/*        const ticket = {*/}
            {/*            ticket: {*/}
            {/*                subject: "This is an React Test",*/}
            {/*                comment: {*/}
            {/*                    body: "Device IMEI: " + values.deviceIMEI + " United Airlines Wireless Number: " + values.phoneNumber*/}
            {/*                }*/}
            {/*            }*/}
            {/*        }*/}

            {/*        //Send to API*/}
            {/*        fetch("/api/zendesk", {*/}
            {/*            method: 'POST',*/}
            {/*            headers: new Headers({*/}
            {/*                'Content-Type': 'application/json'*/}
            {/*            }),*/}
            {/*            body: JSON.stringify(ticket)*/}
            {/*        })*/}
            {/*    }}*/}
            {/*>*/}
            {/*    {({ errors, touched }) => (*/}
            {/*        <Container>*/}
            {/*            <Form>*/}
            {/*                <Row>*/}
            {/*                    <Col md={12}>*/}
            {/*                        <FormGroup>*/}
            {/*                            <label htmlFor="deviceIMEI">Device IMEI</label>*/}
            {/*                            <Field*/}
            {/*                                id="deviceIMEI"*/}
            {/*                                name="deviceIMEI"*/}
            {/*                                placeholder="Enter 15-digit Device IMEI"*/}
            {/*                                className="form-control"*/}
            {/*                            />*/}
            {/*                            {errors.deviceIMEI && touched.deviceIMEI ? (*/}
            {/*                                <div>{errors.deviceIMEI}</div>*/}
            {/*                            ) : null}*/}
            {/*                        </FormGroup>*/}
            {/*                    </Col>*/}
            {/*                    <Col md={12}>*/}
            {/*                        <FormGroup>*/}
            {/*                            <label htmlFor="phoneNumber">*/}
            {/*                                United Airlines Wireless Number*/}
            {/*                            </label>*/}
            {/*                            <Field*/}
            {/*                                id="phoneNumber"*/}
            {/*                                name="phoneNumber"*/}
            {/*                                placeholder="Enter 10-digit Wireless Number"*/}
            {/*                                className="form-control"*/}
            {/*                            />*/}
            {/*                            {errors.phoneNumber && touched.phoneNumber ? (*/}
            {/*                                <div>{errors.phoneNumber}</div>*/}
            {/*                            ) : null}*/}
            {/*                        </FormGroup>*/}
            {/*                    </Col>*/}
            {/*                    <Col md={12}>*/}
            {/*                        <FormGroup>*/}
            {/*                            <button type="submit" className="btn btn-primary btn-sm">Submit</button>*/}
            {/*                        </FormGroup>*/}
            {/*                    </Col>*/}
            {/*                </Row>*/}
            {/*            </Form>*/}
            {/*        </Container>*/}
            {/*    )}*/}
            {/*</Formik>*/}
        </div>
    );
};

export default United;
