import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Row,
  Col,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import "./NavMenu.css";

import logo from "../images/CC3_Solutions_Horizontal.png";

const NavMenu = () => {
  // static displayName = NavMenu.name;

  const [collapsed, setCollapsed] = useState(true);
  // const [dropdownOpen, setDropdownOpen] = useState(true);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  // const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <header>
      <Navbar
        className="ng-white border-bottom box-shadow mb-3 navbar navbar-inverse"
        light
        expand="lg"
        fixed="top"
      >
        <NavbarBrand tag={Link} to="/">
          <img alt="CC3 Solutions, LLC" src={logo} />
        </NavbarBrand>

        <NavbarToggler onClick={toggleNavbar} className="mr-2 text-right" />
        <Collapse
          className="navbar-collapse collapse text-right"
          isOpen={!collapsed}
          navbar
        >
          <ul className="nav navbar-nav me-auto mb-2 mb-lg-0 text-right top_nav">
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret className="text-dark animateColor">
                Solutions
              </DropdownToggle>
              <DropdownMenu>
                <div className="dropdown-content">
                  <Container fluid="true">
                    <h2 className="text-left">Solutions</h2>
                    <Row>
                      <Col sm="4">
                        <HashLink
                          className="column_link"
                          smooth
                          to="/#mobility"
                        >
                          <div className="link_wrap">
                            <i className="fas fa-mobile"></i>
                            <div className="link_txt">
                              <div className="link_title">Mobility</div>
                              <div className="link_info">
                                {" "}
                                Mobile phone environments are ever-changing and
                                end-user requests are endless. Our teams manage
                                the entire mobility lifecycle from pre-sales all
                                the way through lifecycle management and beyond.
                              </div>
                            </div>
                          </div>
                        </HashLink>
                      </Col>
                      <Col sm="4">
                        <HashLink
                          className="column_link"
                          smooth
                          to="/#hybridit"
                        >
                          <div className="link_wrap">
                            <i className="fas fa-tachometer-alt"></i>
                            <div className="link_txt">
                              <div className="link_title">Hybrid IT</div>
                              <div className="link_info">
                                {" "}
                                We know that customers today are faced with a
                                choice - look to the cloud or tackle in house.
                                Our teams are experts at helping customers
                                determine when and where to deploy their
                                resources.
                              </div>
                            </div>
                          </div>
                        </HashLink>
                      </Col>
                      <Col sm="4">
                        <HashLink className="column_link" smooth to="/#network">
                          <div className="link_wrap">
                            <i className="fas fa-wifi"></i>
                            <div className="link_txt">
                              <div className="link_title">Network</div>
                              <div className="link_info">
                                The public internet has become the corporate
                                network. From shared to dedicated, let our team
                                help determine what’s best for your business
                              </div>
                            </div>
                          </div>
                        </HashLink>
                      </Col>
                      <Col sm="4">
                        <HashLink className="column_link" smooth to="/#voice">
                          <div className="link_wrap">
                            <i className="fas fa-phone-volume"></i>
                            <div className="link_txt">
                              <div className="link_title">Voice</div>
                              <div className="link_info">
                                Communication and collaboration are key in
                                today’s business environment. We can help you
                                connect your teams working from anywhere,
                                anytime.
                              </div>
                            </div>
                          </div>
                        </HashLink>
                      </Col>
                      <Col sm="4">
                        <HashLink
                          className="column_link"
                          smooth
                          to="/#security"
                        >
                          <div className="link_wrap">
                            <i className="fas fa-lock"></i>
                            <div className="link_txt">
                              <div className="link_title">Security</div>
                              <div className="link_info">
                                From end point security to comprehensive threat
                                management platforms, we have the expertise and
                                solutions to secure and manage your environment.
                              </div>
                            </div>
                          </div>
                        </HashLink>
                      </Col>
                      <Col sm="4">
                        <HashLink
                          className="column_link"
                          smooth
                          to="/#warehouse"
                        >
                          <div className="link_wrap">
                            <i className="fas fa-boxes"></i>
                            <div className="link_txt">
                              <div className="link_title">Warehousing</div>
                              <div className="link_info">
                                Let our team do the heavy lifting for all things
                                pertaining to mobile equipment. 
                              </div>
                            </div>
                          </div>
                        </HashLink>
                      </Col>
                      <Col sm="4">
                        <HashLink
                          className="column_link"
                          smooth
                          to="/#sourcing"
                        >
                          <div className="link_wrap">
                            <i className="fas fa-warehouse"></i>
                            <div className="link_txt">
                              <div className="link_title">Sourcing</div>
                              <div className="link_info">
                                We specialize in sourcing certified A-Stock
                                mobile devices, mobile hotspots, wireless
                                routers, signal boosters, and accessories to
                                meet the needs of your business. Our goal is to
                                procure the equipment at or below budget.
                              </div>
                            </div>
                          </div>
                        </HashLink>
                      </Col>
                      <Col sm="4">
                        <HashLink
                          className="column_link"
                          smooth
                          to="/#corporatebuyback"
                        >
                          <div className="link_wrap">
                            <i className="fas fa-money-bill"></i>
                            <div className="link_txt">
                              <div className="link_title">
                                Corporate Buyback
                              </div>
                              <div className="link_info">
                                As a trusted partner, we offer a simple and
                                profitable process for organizations looking to
                                get the maximum return on their end-of-life
                                mobile devices
                              </div>
                            </div>
                          </div>
                        </HashLink>
                      </Col>
                      <Col sm="4">
                        <HashLink
                          className="column_link"
                          smooth
                          to="/#corporaterepair"
                        >
                          <div className="link_wrap">
                            <i className="fas fa-tools"></i>
                            <div className="link_txt">
                              <div className="link_title">Corporate Repair</div>
                              <div className="link_info">
                                Our corporate repair program provides
                                replacement equipment in advance, which
                                minimizes down-time and ensures end-users aren’t
                                wasting time waiting around for repairs.
                              </div>
                            </div>
                          </div>
                        </HashLink>
                      </Col>
                      <Col sm="12">
                        <div className="my-3">
                          <hr />
                          <h3 className="text-center">Industries</h3>
                          <div className="d-flex justify-content-around">
                            <a href="/#" className="ind_link">
                              Healthcare
                            </a>
                            <a href="/#" className="ind_link">
                              Supply Chain
                            </a>
                            <a href="/#" className="ind_link">
                              Restaurant
                            </a>
                            <a href="/#" className="ind_link">
                              Logistics
                            </a>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <NavLink
                tag={Link}
                className="text-dark animateColor"
                to="/about"
              >
                About
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                className="text-dark animateColor"
                to="/careers"
              >
                Careers
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                className="text-dark animateColor"
                to="/partners"
              >
                Partners
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                className="text-dark animateColor"
                to="/contact"
              >
                Contact
              </NavLink>
            </NavItem>
          </ul>
        </Collapse>
      </Navbar>
    </header>
  );
};

export default NavMenu;
