import React, { useState, useEffect } from "react";
import Services from "./Services";
import Loader from "react-loader-spinner";

import warehouseImg from "../images/warehouse_wood.jpg";

const Warehousing = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 500));

      setLoading(!loading);
    };
    loadData();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Loader
          type="Puff"
          color="#f3763cd2"
          height={100}
          width={100}
          timeout={3000} //3 secs
        />
      </div>
    );
  } else {
    return (
      <>
        <div style={{ marginTop: "11rem", textAlign: "center" }}>
          <Services
            autoPlay={3000}
            infinite={true}
            bkSize="cover"
            borderColor="#ffe229"
            title="Warehousing Solutions"
            slides={[
              {
                icon: "fa-warehouse",
                service: "Staging & Kitting",
                description: `
                Our custom Staging & Kitting services can be tailored to your businesses needs. 
                Popular services like device setup & configuration, program installation/updates, security protocols/data erasure, accessory kitting & instructional setup, all the way down to the precise deployment of these devices to ensure these devices get to where they need to be. 
                It can all be done with us! Our tech experts can store your equipment here at warehouse facilities or even repair devices that get damaged out in the field.
                `,
                image: warehouseImg,
              },
            ]}
          />
        </div>
      </>
    );
  }
};

export default Warehousing;
