import React, { useEffect,useState } from "react";
import Services from "./Services";
import Loader from "react-loader-spinner";

import icImg from "../images/internetConnectivity.jpg";
import ethernetImg from "../images/ethernet.jpg";
import sdWanImg from "../images/sdWan.jpg";

const Network = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 500));

      setLoading(!loading);
    };
    loadData();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Loader
          type="Puff"
          color="#f3763cd2"
          height={100}
          width={100}
          timeout={3000} //3 secs
        />
      </div>
    );
  } else {
    return (
      <>
        <div style={{ marginTop: "11rem", textAlign: "center" }}>
          <Services
            autoPlay={3000}
            infinite={true}
            borderColor="#f3763c"
            title="Network Solutions"
            slides={[
              {
                icon: "fa-wifi",
                service: "Internet Connectivity",
                description: `Shared or dedicated? Symmetircal or asymmetrical? We've got you covered! Our team can help you identify,
                  the right connection type and bandwidth for each location. We offer dedicated internet with Symmetrical speeds and service level agreements (SLA),
                  as well as other solutions that are perfect for basic HTML browsing and non-critical traffic.`,
                image: icImg,
              },
              {
                icon: "fa-network-wired",
                service: "Switched Ethernet",
                description: `Switched Ethernet Service offers simplicity, scalability, flexibility and affordability.
                  One network design does not fit all, so flexible configurations such as site to site, headquarter to branch offices or
                  fully meshed, enable the network to fit your business. Maximize your network budget by purchasing the bandwidth needed today while knowing
                  the service can easily be upgraded to higher speeds, from megabits to gigabits.`,
                image: ethernetImg,
              },
              {
                icon: "fa-wifi",
                service: "SD-WAN",
                description: `Help make your WAN more productive and flexible by allowing a hybrid networking approach which can integrate multiple network connections. While the
                  benefits are undeniable, choosing the right network components and design can be a daunting task. We can assist you in pairing the right network connections
                  and architecture to meet your needs.`,
                image: sdWanImg,
              },
            ]}
          />
        </div>
      </>
    );
  }
};

export default Network;
