import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Jumbotron,
  Spinner,
} from "reactstrap";

import { animated, config, useSpring } from "react-spring";
import Loader from "react-loader-spinner";
import { Helmet } from "react-helmet";
import GoogleMapReact from "google-map-react";
import { Formik } from "formik";
import * as Yup from "yup";

// msal react
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../Config.js";
/*import { callMsGraph } from "../graph";*/

import planeImg from "../images/SVG/plane.png";

const GoogleMaps = ({ coords }) => {
  const renderMarkers = (map, maps) => {
    coords.map((item, i) => {
      let marker = new maps.Marker({
        position: { lat: item.lat, lng: item.lng },
        map,
        title: item.title,
      });

      return marker;
    });
  };

  return (
    <div style={{ width: "100%", height: "300px", padding: "0 1.5rem" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyCzxXvwbxlM6Nek-s63mXn2k_RZJTdjc-w",
        }}
        defaultCenter={{ lat: 40.5259636, lng: -90.402838 }}
        defaultZoom={5}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => {
          renderMarkers(map, maps);
          map.mapTypeId = "roadmap";
          map.mapTypeControl = true;
        }}
      ></GoogleMapReact>
    </div>
  );
};

const Contact = () => {
  // Init State
  const { instance } = useMsal();
  const [success, setSuccess] = useState(false);
  const [formMessage, setFormMessage] = useState("");
  const [loading, setLoading] = useState(true);

  // form schema
  const schema = Yup.object({
    firstName: Yup.string().required("Required").trim(),
    lastName: Yup.string().required("Required").trim(),
    email: Yup.string().email().required("Required").trim(),
    phoneNumber: Yup.string()
      .required("Required")
      .length(10, `Phone Number Must Be 10 Characters`)
      .trim(),
    businessName: Yup.string().required("Required").trim(),
    address: Yup.string().required("Required").trim(),
    message: Yup.string().required("Required").trim(),
  });

  // react-spring animations
  const props = useSpring({
    from: { transform: "translate3d(-400%,300%,0)" },
    to: { transform: "translate3d(0,0,0)" },
    config: config.slow,
  });

  const formProps = useSpring({
    from: { transform: "translate3d(0,100%,0)", opacity: 0 },
    to: { transform: "translate3d(0,0,0)", opacity: 1 },
    delay: 1800,
    config: config.wobbly,
  });

  const titleProps = useSpring({
    from: { transform: "translate3d(0,-100%,0)", opacity: 0 },
    to: { transform: "translate3d(0,0,0)", opacity: 1 },
    delay: 1000,
    config: config.wobbly,
  });

  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 500));

      setLoading(!loading);
    };
    loadData();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Loader
          type="Puff"
          color="#f3763cd2"
          height={100}
          width={100}
          timeout={3000} //3 secs
        />
      </div>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>CC3Solutions - Contact</title>
          <meta name="description" content="Contact Us" />
        </Helmet>
        <div className="contact">
          <div className="d-flex flex-column justify-content-center text-center">
            <animated.div style={titleProps}>
              <h1 style={{ textShadow: "4px 2px 5px rgba(112,128,144,0.75)" }}>
                <span style={{ fontWeight: "600" }}>Contact</span> Us
              </h1>
              <h3 className="lead">
                Have a question? Our friendly, knowledgeable team is here to
                help.
              </h3>
            </animated.div>
          </div>
          <Container>
            <div className="contact_bottom_half">
              <Row>
                <Col sm="12">
                  <animated.div style={props} className="my-3 text-center">
                    <img
                      src={planeImg}
                      className="contact_plane"
                      alt="paper plane"
                    />
                  </animated.div>
                </Col>
                {/*<Col sm="12">*/}
                {/*    <animated.div style={formProps}>*/}
                {/*        <Formik*/}
                {/*            initialValues={{*/}
                {/*                firstName: "",*/}
                {/*                lastName: "",*/}
                {/*                email: "",*/}
                {/*                phoneNumber: "",*/}
                {/*                businessName: "",*/}
                {/*                address: "",*/}
                {/*                message: "",*/}
                {/*            }}*/}
                {/*            validationSchema={schema}*/}
                {/*            onSubmit={(values, actions) => {*/}
                {/*                actions.setSubmitting(true);*/}

                {/*                const formVals = {*/}
                {/*                    firstName: values.firstName,*/}
                {/*                    lastName: values.lastName,*/}
                {/*                    email: values.email,*/}
                {/*                    message: values.message,*/}
                {/*                    phoneNumber: values.phoneNumber,*/}
                {/*                    businessName: values.businessName,*/}
                {/*                    address: values.address*/}
                {/*                }*/}

                {/*                fetch("api/form", {*/}
                {/*                    method: "POST",*/}
                {/*                    headers: {*/}
                {/*                        'Content-Type': 'application/json'*/}
                {/*                    },*/}
                {/*                    body: JSON.stringify(formVals)*/}
                {/*                }).then((res) => {*/}
                {/*                    //console.log(res)*/}
                {/*                    actions.setSubmitting(false);*/}
                {/*                    setSuccess(true);*/}
                {/*                    setFormMessage(*/}
                {/*                        "Thanks For Reaching Out! We Will Get Back To You Shortly!"*/}
                {/*                    );*/}
                {/*                }).catch((e) => {*/}
                {/*                    console.log(e)*/}
                {/*                    setFormMessage(e)*/}
                {/*                })*/}

                {/*            }}*/}
                {/*        >*/}
                {/*            {({*/}
                {/*                isSubmitting,*/}
                {/*                handleSubmit,*/}
                {/*                handleChange,*/}
                {/*                errors,*/}
                {/*                isValid,*/}
                {/*                values,*/}
                {/*                touched,*/}
                {/*            }) => {*/}
                {/*                if (!isSubmitting && success) {*/}
                {/*                    return (*/}
                {/*                        <>*/}
                {/*                            <div className="text-center">*/}
                {/*                                <h1>*/}
                {/*                                    {formMessage}*/}
                {/*                                </h1>*/}
                {/*                            </div>*/}
                {/*                        </>*/}
                {/*                    );*/}
                {/*                }*/}
                {/*                if (isSubmitting) {*/}
                {/*                    return (*/}
                {/*                        <>*/}
                {/*                            <div className="text-center">*/}
                {/*                                <Spinner*/}
                {/*                                    style={{ width: "6rem", height: "6rem" }}*/}
                {/*                                    type="grow"*/}
                {/*                                    color="primary"*/}
                {/*                                />*/}
                {/*                            </div>*/}
                {/*                        </>*/}
                {/*                    );*/}
                {/*                } else {*/}
                {/*                    return (*/}
                {/*                        <Form onSubmit={handleSubmit}>*/}
                {/*                            <Row form>*/}
                {/*                                <Col md={6}>*/}
                {/*                                    <FormGroup>*/}
                {/*                                        <Input*/}
                {/*                                            type="text"*/}
                {/*                                            name="firstName"*/}
                {/*                                            placeholder="First Name"*/}
                {/*                                            bsSize="lg"*/}
                {/*                                            onChange={handleChange}*/}
                {/*                                            value={values.firstName}*/}
                {/*                                            className={*/}
                {/*                                                !touched.firstName && isValid*/}
                {/*                                                    ? "border-secondary"*/}
                {/*                                                    : errors.firstName*/}
                {/*                                                        ? "border-danger"*/}
                {/*                                                        : "border-success"*/}
                {/*                                            }*/}
                {/*                                        />*/}
                {/*                                        {errors.firstName && (*/}
                {/*                                            <div className="text-danger">*/}
                {/*                                                {errors.firstName}*/}
                {/*                                            </div>*/}
                {/*                                        )}*/}
                {/*                                    </FormGroup>*/}
                {/*                                </Col>*/}
                {/*                                <Col md={6}>*/}
                {/*                                    <FormGroup>*/}
                {/*                                        <Input*/}
                {/*                                            type="text"*/}
                {/*                                            name="lastName"*/}
                {/*                                            placeholder="Last Name"*/}
                {/*                                            bsSize="lg"*/}
                {/*                                            onChange={handleChange}*/}
                {/*                                            value={values.lastName}*/}
                {/*                                            className={*/}
                {/*                                                !touched.lastName && isValid*/}
                {/*                                                    ? "border-secondary"*/}
                {/*                                                    : errors.lastName*/}
                {/*                                                        ? "border-danger"*/}
                {/*                                                        : "border-success"*/}
                {/*                                            }*/}
                {/*                                        />*/}
                {/*                                        {errors.lastName && (*/}
                {/*                                            <div className="text-danger">*/}
                {/*                                                {errors.lastName}*/}
                {/*                                            </div>*/}
                {/*                                        )}*/}
                {/*                                    </FormGroup>*/}
                {/*                                </Col>*/}
                {/*                                <Col md={6}>*/}
                {/*                                    <FormGroup>*/}
                {/*                                        <Input*/}
                {/*                                            type="email"*/}
                {/*                                            name="email"*/}
                {/*                                            placeholder="Email Address"*/}
                {/*                                            bsSize="lg"*/}
                {/*                                            onChange={handleChange}*/}
                {/*                                            value={values.email}*/}
                {/*                                            className={*/}
                {/*                                                !touched.email && isValid*/}
                {/*                                                    ? "border-secondary"*/}
                {/*                                                    : errors.email*/}
                {/*                                                        ? "border-danger"*/}
                {/*                                                        : "border-success"*/}
                {/*                                            }*/}
                {/*                                        />*/}
                {/*                                        {errors.email && (*/}
                {/*                                            <div className="text-danger">*/}
                {/*                                                {errors.email}*/}
                {/*                                            </div>*/}
                {/*                                        )}*/}
                {/*                                    </FormGroup>*/}
                {/*                                </Col>*/}
                {/*                                <Col md={6}>*/}
                {/*                                    <FormGroup>*/}
                {/*                                        <Input*/}
                {/*                                            type="text"*/}
                {/*                                            name="phoneNumber"*/}
                {/*                                            placeholder="Phone Number"*/}
                {/*                                            bsSize="lg"*/}
                {/*                                            onChange={handleChange}*/}
                {/*                                            value={values.phoneNumber}*/}
                {/*                                            className={*/}
                {/*                                                !touched.phoneNumber && isValid*/}
                {/*                                                    ? "border-secondary"*/}
                {/*                                                    : errors.phoneNumber*/}
                {/*                                                        ? "border-danger"*/}
                {/*                                                        : "border-success"*/}
                {/*                                            }*/}
                {/*                                        />*/}
                {/*                                        {errors.phoneNumber && (*/}
                {/*                                            <div className="text-danger">*/}
                {/*                                                {errors.phoneNumber}*/}
                {/*                                            </div>*/}
                {/*                                        )}*/}
                {/*                                    </FormGroup>*/}
                {/*                                </Col>*/}
                {/*                                <Col md={6}>*/}
                {/*                                    <FormGroup>*/}
                {/*                                        <Input*/}
                {/*                                            type="text"*/}
                {/*                                            name="businessName"*/}
                {/*                                            placeholder="Business Name"*/}
                {/*                                            bsSize="lg"*/}
                {/*                                            onChange={handleChange}*/}
                {/*                                            value={values.businessName}*/}
                {/*                                            className={*/}
                {/*                                                !touched.businessName && isValid*/}
                {/*                                                    ? "border-secondary"*/}
                {/*                                                    : errors.businessName*/}
                {/*                                                        ? "border-danger"*/}
                {/*                                                        : "border-success"*/}
                {/*                                            }*/}
                {/*                                        />*/}
                {/*                                        {errors.businessName && (*/}
                {/*                                            <div className="text-danger">*/}
                {/*                                                {errors.businessName}*/}
                {/*                                            </div>*/}
                {/*                                        )}*/}
                {/*                                    </FormGroup>*/}
                {/*                                </Col>*/}
                {/*                                <Col md={6}>*/}
                {/*                                    <FormGroup>*/}
                {/*                                        <Input*/}
                {/*                                            type="text"*/}
                {/*                                            name="address"*/}
                {/*                                            placeholder="Address"*/}
                {/*                                            bsSize="lg"*/}
                {/*                                            onChange={handleChange}*/}
                {/*                                            value={values.address}*/}
                {/*                                            className={*/}
                {/*                                                !touched.address && isValid*/}
                {/*                                                    ? "border-secondary"*/}
                {/*                                                    : errors.address*/}
                {/*                                                        ? "border-danger"*/}
                {/*                                                        : "border-success"*/}
                {/*                                            }*/}
                {/*                                        />*/}
                {/*                                        {errors.address && (*/}
                {/*                                            <div className="text-danger">*/}
                {/*                                                {errors.address}*/}
                {/*                                            </div>*/}
                {/*                                        )}*/}
                {/*                                    </FormGroup>*/}
                {/*                                </Col>*/}
                {/*                                <Col md={12}>*/}
                {/*                                    <FormGroup>*/}
                {/*                                        <Input*/}
                {/*                                            type="textarea"*/}
                {/*                                            name="message"*/}
                {/*                                            placeholder="Message"*/}
                {/*                                            bsSize="lg"*/}
                {/*                                            style={{ height: "200px" }}*/}
                {/*                                            onChange={handleChange}*/}
                {/*                                            value={values.message}*/}
                {/*                                            className={*/}
                {/*                                                !touched.message && isValid*/}
                {/*                                                    ? "border-secondary"*/}
                {/*                                                    : errors.message*/}
                {/*                                                        ? "border-danger"*/}
                {/*                                                        : "border-success"*/}
                {/*                                            }*/}
                {/*                                        />*/}
                {/*                                        {errors.message && (*/}
                {/*                                            <div className="text-danger">*/}
                {/*                                                {errors.message}*/}
                {/*                                            </div>*/}
                {/*                                        )}*/}
                {/*                                    </FormGroup>*/}
                {/*                                </Col>*/}
                {/*                            </Row>*/}
                {/*                            <Row>*/}
                {/*                                <Col className="text-center">*/}
                {/*                                    <Button className="contactBtn" type="submit">*/}
                {/*                                        Submit*/}
                {/*    </Button>*/}
                {/*                                </Col>*/}
                {/*                            </Row>*/}
                {/*                        </Form>*/}
                {/*                    );*/}
                {/*                }*/}
                {/*            }}*/}
                {/*        </Formik>*/}
                {/*    </animated.div>*/}
                {/*</Col>*/}
                <Col md="12">
                  <Container>
                    <Jumbotron className="jumbotron contact_location text-center">
                      <Row>
                        <Col>
                          <div className="contact_email text-center">
                            <h3>Live Chat</h3>
                            <i class="fas fa-comments fa-5x"></i>
                            <a id="myChat" href="mailTo:info@cc3solutions.com">
                              Click Here To Chat Now
                            </a>
                          </div>
                        </Col>
                        <Col>
                          <div className="contact_email text-center">
                            <h3>Email us</h3>
                            <i className="fas fa-envelope fa-5x"></i>
                            <a href="mailTo:info@cc3solutions.com">
                              info@cc3solutions.com
                            </a>
                          </div>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md="6">
                          <div className="contact_stl_head">
                            <h3>St. Louis, MO (Corporate Headquarters)</h3>
                            <div className="contact_stl_phone">
                              <i className="fas fa-phone fa-3x"></i>
                              <a
                                href="tel:1-877-344-3008"
                                style={{ fontSize: "2rem" }}
                              >
                                1-877-344-3008
                              </a>
                            </div>
                            <div className="contact_stl_address">
                              <i className="fas fa-map-marked-alt fa-3x"></i>
                              <p>CC3 Solutions, LLC</p>
                              <p>13075 Manchester Road</p>
                              <p>Suite 375</p>
                              <p>St. Louis, MO 63131</p>
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="contact_wi_head">
                            <h3>Middleton, WI</h3>
                            <div className="contact_wi_phone">
                              <i className="fas fa-phone fa-3x"></i>
                              <a
                                href="tel:608-888-0065"
                                style={{ fontSize: "2rem" }}
                              >
                                608-888-0065
                              </a>
                            </div>
                            <div className="contact_wi_address">
                              <i className="fas fa-map-marked-alt fa-3x"></i>
                              <p>1600 Aspen Commons Blvd</p>
                              <p>Suite 580</p>
                              <p>Middleton, WI 53562</p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Jumbotron>
                  </Container>
                  <div>
                    <GoogleMaps
                      coords={[
                        {
                          lat: 38.5259636,
                          lng: -90.402838,
                          title: "St. Louis, MO",
                        },
                        {
                          lat: 43.11528,
                          lng: -89.52492,
                          title: "Middleton, WI",
                        },
                      ]}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
};

//export default Contact;
//import React, { useState, useEffect } from "react";
//import {
//    Container,
//    Row,
//    Col,
//    Form,
//    FormGroup,
//    Input,
//    Button,
//    Jumbotron,
//    Spinner,
//} from "reactstrap";

//import { animated, config, useSpring } from "react-spring";
//import { Helmet } from "react-helmet";
//import GoogleMapReact from "google-map-react";
//import { Formik } from "formik";
//import * as Yup from "yup";

//// msal react
//import { useMsal } from "@azure/msal-react";
//import { loginRequest } from "../Config.js";
///*import { callMsGraph } from "../graph";*/

//import planeImg from "../images/SVG/plane.png";

//const GoogleMaps = ({ coords }) => {
//    const renderMarkers = (map, maps) => {
//        coords.map((item, i) => {
//            let marker = new maps.Marker({
//                position: { lat: item.lat, lng: item.lng },
//                map,
//                title: item.title,
//            });

//            return marker;
//        });
//    };

//    return (
//        <div style={{ width: "100%", height: "300px", padding: "0 1.5rem" }}>
//            <GoogleMapReact
//                bootstrapURLKeys={{
//                    key: "AIzaSyCzxXvwbxlM6Nek-s63mXn2k_RZJTdjc-w",
//                }}
//                defaultCenter={{ lat: 40.5259636, lng: -90.402838 }}
//                defaultZoom={5}
//                yesIWantToUseGoogleMapApiInternals={true}
//                onGoogleApiLoaded={({ map, maps }) => {
//                    renderMarkers(map, maps);
//                    map.mapTypeId = "roadmap";
//                    map.mapTypeControl = true;
//                }}
//            ></GoogleMapReact>
//        </div>
//    );
//};

//const Contact = () => {
//    // Init State
//    const { instance } = useMsal();
//    const [success, setSuccess] = useState(false);
//    const [formMessage, setFormMessage] = useState("");

//    // form schema
//    const schema = Yup.object({
//        firstName: Yup.string().required("Required").trim(),
//        lastName: Yup.string().required("Required").trim(),
//        email: Yup.string().email().required("Required").trim(),
//        phoneNumber: Yup.string()
//            .required("Required")
//            .length(10, `Phone Number Must Be 10 Characters`)
//            .trim(),
//        businessName: Yup.string().required("Required").trim(),
//        address: Yup.string().required("Required").trim(),
//        message: Yup.string().required("Required").trim(),
//    });

//    // react-spring animations
//    const props = useSpring({
//        from: { transform: "translate3d(-400%,300%,0)" },
//        to: { transform: "translate3d(0,0,0)" },
//        config: config.slow,
//    });

//    const formProps = useSpring({
//        from: { transform: "translate3d(0,100%,0)", opacity: 0 },
//        to: { transform: "translate3d(0,0,0)", opacity: 1 },
//        delay: 1800,
//        config: config.wobbly,
//    });

//    const titleProps = useSpring({
//        from: { transform: "translate3d(0,-100%,0)", opacity: 0 },
//        to: { transform: "translate3d(0,0,0)", opacity: 1 },
//        delay: 1000,
//        config: config.wobbly,
//    });

//    return (
//        <>
//            <Helmet>
//                <title>CC3Solutions - Contact</title>
//                <meta name="description" content="Contact Us" />
//            </Helmet>
//            <div className="contact">
//                <div className="d-flex flex-column justify-content-center text-center">
//                    <animated.div style={titleProps}>
//                        <h1>Contact Us</h1>
//                        <p className="lead">
//                            Have a question? Our friendly, knowledgeable team is here to help.
//                            Click the link below.
//              {/* Simply fill out the form below. */}
//                        </p>
//                    </animated.div>
//                </div>
//                <Container>
//                    <div className="contact_bottom_half">
//                        <Row>
//                            <Col sm="12">
//                                <animated.div style={props} className="my-3 text-center">
//                                    <img
//                                        src={planeImg}
//                                        className="contact_plane"
//                                        alt="paper plane"
//                                    />
//                                </animated.div>
//                            </Col>
//                            <Col sm="12">
//                                <animated.div style={formProps}>
//                                    <Formik
//                                        initialValues={{
//                                            firstName: "",
//                                            lastName: "",
//                                            email: "",
//                                            phoneNumber: "",
//                                            businessName: "",
//                                            address: "",
//                                            message: "",
//                                        }}
//                                        validationSchema={schema}
//                                        onSubmit={(values, actions) => {
//                                            actions.setSubmitting(true);

//                                            const formVals = {
//                                                firstName: values.firstName,
//                                                lastName: values.lastName,
//                                                email: values.email,
//                                                message: values.message,
//                                                phoneNumber: values.phoneNumber,
//                                                businessName: values.businessName,
//                                                address: values.address
//                                            }

//                                            fetch("api/form", {
//                                                method: "POST",
//                                                headers: {
//                                                    'Content-Type': 'application/json'
//                                                },
//                                                body: JSON.stringify(formVals)
//                                            }).then((res) => {
//                                                console.log(res.json())
//                                                actions.setSubmitting(false);
//                                                setSuccess(true);
//                                                setFormMessage(
//                                                    "Thanks For Reaching Out! We Will Get Back To You Shortly!"
//                                                );
//                                            }).catch((e) => {
//                                                console.log(e)
//                                                setFormMessage(e)
//                                            })

//                                        }}
//                                    >
//                                        {({
//                                            isSubmitting,
//                                            handleSubmit,
//                                            handleChange,
//                                            errors,
//                                            isValid,
//                                            values,
//                                            touched,
//                                        }) => {
//                                            if (!isSubmitting && success) {
//                                                return (
//                                                    <>
//                                                        <div className="text-center">
//                                                            <h1>
//                                                                {formMessage}
//                                                            </h1>
//                                                        </div>
//                                                    </>
//                                                );
//                                            }
//                                            if (isSubmitting) {
//                                                return (
//                                                    <>
//                                                        <div className="text-center">
//                                                            <Spinner
//                                                                style={{ width: "6rem", height: "6rem" }}
//                                                                type="grow"
//                                                                color="primary"
//                                                            />
//                                                        </div>
//                                                    </>
//                                                );
//                                            } else {
//                                                return (
//                                                    <Form onSubmit={handleSubmit}>
//                                                        <Row form>
//                                                            <Col md={6}>
//                                                                <FormGroup>
//                                                                    <Input
//                                                                        type="text"
//                                                                        name="firstName"
//                                                                        placeholder="First Name"
//                                                                        bsSize="lg"
//                                                                        onChange={handleChange}
//                                                                        value={values.firstName}
//                                                                        className={
//                                                                            !touched.firstName && isValid
//                                                                                ? "border-secondary"
//                                                                                : errors.firstName
//                                                                                    ? "border-danger"
//                                                                                    : "border-success"
//                                                                        }
//                                                                    />
//                                                                    {errors.firstName && (
//                                                                        <div className="text-danger">
//                                                                            {errors.firstName}
//                                                                        </div>
//                                                                    )}
//                                                                </FormGroup>
//                                                            </Col>
//                                                            <Col md={6}>
//                                                                <FormGroup>
//                                                                    <Input
//                                                                        type="text"
//                                                                        name="lastName"
//                                                                        placeholder="Last Name"
//                                                                        bsSize="lg"
//                                                                        onChange={handleChange}
//                                                                        value={values.lastName}
//                                                                        className={
//                                                                            !touched.lastName && isValid
//                                                                                ? "border-secondary"
//                                                                                : errors.lastName
//                                                                                    ? "border-danger"
//                                                                                    : "border-success"
//                                                                        }
//                                                                    />
//                                                                    {errors.lastName && (
//                                                                        <div className="text-danger">
//                                                                            {errors.lastName}
//                                                                        </div>
//                                                                    )}
//                                                                </FormGroup>
//                                                            </Col>
//                                                            <Col md={6}>
//                                                                <FormGroup>
//                                                                    <Input
//                                                                        type="email"
//                                                                        name="email"
//                                                                        placeholder="Email Address"
//                                                                        bsSize="lg"
//                                                                        onChange={handleChange}
//                                                                        value={values.email}
//                                                                        className={
//                                                                            !touched.email && isValid
//                                                                                ? "border-secondary"
//                                                                                : errors.email
//                                                                                    ? "border-danger"
//                                                                                    : "border-success"
//                                                                        }
//                                                                    />
//                                                                    {errors.email && (
//                                                                        <div className="text-danger">
//                                                                            {errors.email}
//                                                                        </div>
//                                                                    )}
//                                                                </FormGroup>
//                                                            </Col>
//                                                            <Col md={6}>
//                                                                <FormGroup>
//                                                                    <Input
//                                                                        type="text"
//                                                                        name="phoneNumber"
//                                                                        placeholder="Phone Number"
//                                                                        bsSize="lg"
//                                                                        onChange={handleChange}
//                                                                        value={values.phoneNumber}
//                                                                        className={
//                                                                            !touched.phoneNumber && isValid
//                                                                                ? "border-secondary"
//                                                                                : errors.phoneNumber
//                                                                                    ? "border-danger"
//                                                                                    : "border-success"
//                                                                        }
//                                                                    />
//                                                                    {errors.phoneNumber && (
//                                                                        <div className="text-danger">
//                                                                            {errors.phoneNumber}
//                                                                        </div>
//                                                                    )}
//                                                                </FormGroup>
//                                                            </Col>
//                                                            <Col md={6}>
//                                                                <FormGroup>
//                                                                    <Input
//                                                                        type="text"
//                                                                        name="businessName"
//                                                                        placeholder="Business Name"
//                                                                        bsSize="lg"
//                                                                        onChange={handleChange}
//                                                                        value={values.businessName}
//                                                                        className={
//                                                                            !touched.businessName && isValid
//                                                                                ? "border-secondary"
//                                                                                : errors.businessName
//                                                                                    ? "border-danger"
//                                                                                    : "border-success"
//                                                                        }
//                                                                    />
//                                                                    {errors.businessName && (
//                                                                        <div className="text-danger">
//                                                                            {errors.businessName}
//                                                                        </div>
//                                                                    )}
//                                                                </FormGroup>
//                                                            </Col>
//                                                            <Col md={6}>
//                                                                <FormGroup>
//                                                                    <Input
//                                                                        type="text"
//                                                                        name="address"
//                                                                        placeholder="Address"
//                                                                        bsSize="lg"
//                                                                        onChange={handleChange}
//                                                                        value={values.address}
//                                                                        className={
//                                                                            !touched.address && isValid
//                                                                                ? "border-secondary"
//                                                                                : errors.address
//                                                                                    ? "border-danger"
//                                                                                    : "border-success"
//                                                                        }
//                                                                    />
//                                                                    {errors.address && (
//                                                                        <div className="text-danger">
//                                                                            {errors.address}
//                                                                        </div>
//                                                                    )}
//                                                                </FormGroup>
//                                                            </Col>
//                                                            <Col md={12}>
//                                                                <FormGroup>
//                                                                    <Input
//                                                                        type="textarea"
//                                                                        name="message"
//                                                                        placeholder="Message"
//                                                                        bsSize="lg"
//                                                                        style={{ height: "200px" }}
//                                                                        onChange={handleChange}
//                                                                        value={values.message}
//                                                                        className={
//                                                                            !touched.message && isValid
//                                                                                ? "border-secondary"
//                                                                                : errors.message
//                                                                                    ? "border-danger"
//                                                                                    : "border-success"
//                                                                        }
//                                                                    />
//                                                                    {errors.message && (
//                                                                        <div className="text-danger">
//                                                                            {errors.message}
//                                                                        </div>
//                                                                    )}
//                                                                </FormGroup>
//                                                            </Col>
//                                                        </Row>
//                                                        <Row>
//                                                            <Col className="text-center">
//                                                                <Button className="contactBtn" type="submit">
//                                                                    Submit
//                                </Button>
//                                                            </Col>
//                                                        </Row>
//                                                    </Form>
//                                                );
//                                            }
//                                        }}
//                                    </Formik>
//                                </animated.div>
//                            </Col>
//                            <Col md="12">
//                                <Container>
//                                    <Jumbotron className="jumbotron contact_location text-center">
//                                        <div className="contact_email text-center">
//                                            <i className="fas fa-envelope fa-5x"></i>
//                                            <a href="mailTo:info@cc3solutions.com">
//                                                info@cc3solutions.com
//                      </a>
//                                            <hr />
//                                        </div>
//                                        <Row>
//                                            <Col md="6">
//                                                <div className="contact_stl_head">
//                                                    <h3>St. Louis, MO (Corporate Headquarters)</h3>
//                                                    <div className="contact_stl_phone">
//                                                        <i className="fas fa-phone fa-3x"></i>
//                                                        <a href="tel:1-877-344-3008">1-877-344-3008</a>
//                                                    </div>
//                                                    <div className="contact_stl_address">
//                                                        <i className="fas fa-map-marked-alt fa-3x"></i>
//                                                        <p>CC3 Solutions, LLC</p>
//                                                        <p>13075 Manchester Road</p>
//                                                        <p>Suite 375</p>
//                                                        <p>St. Louis, MO 63131</p>
//                                                    </div>
//                                                </div>
//                                            </Col>
//                                            <Col md="6">
//                                                <div className="contact_wi_head">
//                                                    <h3>Middleton, WI</h3>
//                                                    <div className="contact_wi_phone">
//                                                        <i className="fas fa-phone fa-3x"></i>
//                                                        <a href="tel:608-888-0065">608-888-0065</a>
//                                                    </div>
//                                                    <div className="contact_wi_address">
//                                                        <i className="fas fa-map-marked-alt fa-3x"></i>
//                                                        <p>1600 Aspen Commons Blvd</p>
//                                                        <p>Suite 580</p>
//                                                        <p>Middleton, WI 53562</p>
//                                                    </div>
//                                                </div>
//                                            </Col>
//                                        </Row>
//                                    </Jumbotron>
//                                </Container>
//                                <div>
//                                    <GoogleMaps
//                                        coords={[
//                                            {
//                                                lat: 38.5259636,
//                                                lng: -90.402838,
//                                                title: "St. Louis, MO",
//                                            },
//                                            { lat: 43.11528, lng: -89.52492, title: "Middleton, WI" },
//                                        ]}
//                                    />
//                                </div>
//                            </Col>
//                        </Row>
//                    </div>
//                </Container>
//            </div>
//        </>
//    );
//};

export default Contact;
