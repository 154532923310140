import React, { useState } from "react";

import pdf from "../pdf/TEM_Terms_and_Conditions.pdf"


const Tem = () => {
   
    return (
        <div className="text-center">
            <iframe width="100%" height="1000px" style={{paddingTop: "150px", border: "none"}} src={pdf} />
        </div>
        )
}

export default Tem;