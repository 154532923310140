import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { Container, Row, Col } from "reactstrap";
import teamImg from "../images/SVG/team.png";
import phoneImg from "../images/phone_02.png";

import { useSpring, animated, config } from "react-spring";
import { Helmet } from "react-helmet";

// Images
import kevin from "../images/KevinKoke.jpg";
import mike from "../images/MikeMundy.jpg";
import jeff from "../images/JeffEldred.jpg";
import david from "../images/DavidLong.jpg";

const About = () => {
  const [arrowScroll, setArrowScroll] = useState(0);
  const [loading, setLoading] = useState(true);

  const props = useSpring({
    to: [{ transform: "translate3d(0,0,0)" }],
    from: { transform: "translate3d(-100%,0,0)" },
    delay: 500,
    config: config.wobbly,
  });

  const props02 = useSpring({
    to: [{ transform: "translate3d(0,0,0)" }],
    from: { transform: "translate3d(0,-500%,0)" },
    delay: 700,
    config: config.wobbly,
  });

  const arrowArr = [];

  for (let i = 0; i < 3; i++) {
    arrowArr.push(<i key={i} className="fas fa-arrow-right fa-2x pr-3"></i>);
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setArrowScroll(window.scrollY);
    });
    const loadData = async () => {
      await new Promise((r) => setTimeout(r,500))

      setLoading(!loading);
    }
    loadData()
  }, []);

  if (loading) {
    return (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}>
        <Loader
          type="Puff"
          color="#f3763cd2"
          height={100}
          width={100}
          timeout={3000} //3 secs
        />
      </div>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>CC3Solutions - About</title>
          <meta
            name="description"
            content="Managing and procuring telecommunications services is challenging for most customers. Businesses of all sizes are constantly faced with a revolving door of account teams, fluctuating costs, challenges delivering new services, and keeping up with the latest industry trends."
          />
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-113481679-1"
          ></script>
        </Helmet>
        <Container fluid="true">
          <div className="d-flex align-items-center about_top_half">
            <Row>
              <Col lg="6">
                <animated.div style={props} className="about_top_img">
                  <img className="img-fluid" src={teamImg} alt="team" />
                </animated.div>
              </Col>
              <Col lg="6">
                <div className="about_bottom_half about_gray d-flex flex-column justify-content-center align-items-start">
                  <animated.div style={props02}>
                    <h1
                      className="text-center"
                      style={{
                        borderBottom: "1px solid slategray",
                        padding: "1rem 0",
                        width: "50%",
                        margin: "0 auto",
                        textAlign: "center",
                        textShadow: "4px 2px 5px rgba(112,128,144,0.5)",
                      }}
                    >
                      Who <span style={{ fontWeight: "600" }}>We</span> Are
                    </h1>
                    <p>
                      <span className="about_cap">O</span>ur executive team
                      spent years at the largest telco firms and formed CC3 with
                      one goal in mind: <br />
                      <br />
                      <span className="easySpan">
                        Make it easy to source and implement new solutions while
                        staying ahead of the technology curve.
                      </span>
                      <br />
                      <br />
                      Since then, we’ve helped over{" "}
                      <span className="customers">5,000 customers</span> procure
                      and install various telecom products and services. Our
                      team has over 350 years of combined experience in the
                      telecom industry and is committed to providing continuity,
                      making the customer experience seamless, and providing the
                      flexibility that large firms can’t.
                    </p>
                  </animated.div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="section section_white">
            <Row>
              <Col
                lg="6"
                className="d-flex align-items-center justify-content-center"
              >
                <div className="about_bottom_half">
                  <p>
                    <span className="about_cap">M</span>anaging and procuring
                    telecommunications services is challenging for most
                    customers. Businesses of all sizes are constantly faced with
                    a revolving door of account teams, fluctuating costs,
                    challenges delivering new services, and keeping up with the
                    latest industry trends.
                  </p>
                  <div
                    className={
                      arrowScroll >= 600
                        ? "animate__animated animate__slideInLeft"
                        : "animate__animated animate__slideOutLeft"
                    }
                  >
                    <h2 className="about_enter d-flex align-items-center justify-content-start flex flex-wrap">
                      {arrowArr.map((item, i) => item)}
                      <span id="about_cc3">CC3 Solutions</span>
                    </h2>
                  </div>
                </div>
              </Col>
              <Col lg="6" className="d-flex align-items-center">
                <div>
                  <img className="img-fluid" src={phoneImg} alt="tech" />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="section_gray">
          <Container>
            <div className="team_top_half my-3">
              <div className="d-flex justify-content-center">
                <h1
                  className="pb-3 mb-3"
                  style={{
                    borderBottom: "1px solid slategray",
                    padding: "3rem 0",
                    margin: "0 auto",
                    textAlign: "center",
                    textShadow: "4px 2px 5px rgba(112,128,144,0.5)",
                  }}
                >
                  <span style={{ fontWeight: "600" }}>Leader</span>ship
                </h1>
              </div>
            </div>
            <div>
              <Row className="text-center d-flex justify-content-between">
                <Col md="6" className="my-3">
                  <figure className="teamMember figure">
                    <img
                      src={david}
                      className="img-fluid rounded-circle"
                      alt="employeeOne"
                      style={{ boxShadow: "0 0 10px slategray" }}
                    />
                    <div className="my-2">
                      <h4 className="fs-4">David Long</h4>
                      <figcaption className="figure-caption">
                        Chief Executive Officer
                      </figcaption>
                    </div>
                  </figure>
                </Col>
                <Col md="6" className="my-3">
                  <figure className="teamMember figure">
                    <img
                      src={jeff}
                      className="img-fluid rounded-circle"
                      style={{ boxShadow: "0 0 10px slategray" }}
                      alt="employeeOne"
                    />
                    <div className="my-2">
                      <h4 className="fs-4">Jeff Eldred</h4>
                      <figcaption className="figure-caption">
                        President - Global Sales
                      </figcaption>
                    </div>
                  </figure>
                </Col>
                <Col md="6" className="my-3">
                  <figure className="teamMember figure">
                    <img
                      src={kevin}
                      className="img-fluid rounded-circle"
                      style={{ boxShadow: "0 0 10px slategray" }}
                      alt="employeeOne"
                    />
                    <div className="my-2">
                      <h4 className=" fs-4">Kevin Koke</h4>
                      <figcaption className="figure-caption">
                        President - Business Development
                      </figcaption>
                    </div>
                  </figure>
                </Col>
                <Col md="6" className="my-3">
                  <figure className="teamMember figure">
                    <img
                      src={mike}
                      className="img-fluid rounded-circle"
                      style={{ boxShadow: "0 0 10px slategray" }}
                      alt="employeeOne"
                    />
                    <div className="my-2">
                      <h4 className=" fs-4">Mike Mundy</h4>
                      <figcaption className="figure-caption">
                        President - Enterprise Sales
                      </figcaption>
                    </div>
                  </figure>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
};

export default About;
