import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import postscribe from "postscribe";
import { useSpring, animated, config } from "react-spring";
import { Helmet } from "react-helmet";
import Loader from "react-loader-spinner";
import Gallery from "react-photo-gallery";
import { HashLink } from "react-router-hash-link";

import {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  EffectCards,
  EffectFlip,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

import groupPic from "../images/SVG/people.png";
import sittingPic from "../images/SVG/sit.png";
import tablePic from "../images/SVG/tab.png";
import awardsPic from "../images/imagesFromDan/awards.jpg";
import chiefsPic from "../images/imagesFromDan/chiefs.jpg";
import fallFestival from "../images/imagesFromDan/fallFestival.jpg";
import fallPumpkins from "../images/imagesFromDan/fallPumpkins.jpg";
import kitchen from "../images/imagesFromDan/kitchen.jpg";
import chiefs02 from "../images/imagesFromDan/chiefs_02.jpg";
import office01 from "../images/imagesFromDan/office_01.gif";
import office02 from "../images/imagesFromDan/office_02.gif";
import boardRoom from "../images/imagesFromDan/boardRoom_01.gif";
import liz from "../images/imagesFromDan/liz.jpg";
import clint from "../images/imagesFromDan/clint.png";
import rachel from "../images/imagesFromDan/rachel.jpg";
import tableMeeting from "../images/tableMeeting_still.png";

import careers from "../styles/careers.module.css";
import "swiper/swiper-bundle.css";

const Careers = () => {
  const [loading, setLoading] = useState(true);

  const photos = [
    {
      src: kitchen,
      width: 4,
      height: 3,
    },
    {
      src: fallFestival,
      width: 5,
      height: 4,
    },
    {
      src: office01,
      width: 2,
      height: 2,
    },
    {
      src: chiefs02,
      width: 4,
      height: 3,
    },
  ];

  const tableAni = useSpring({
    to: [{ transform: "translate3d(0,0,0)", opacity: 1 }],
    from: { transform: "translate3d(100%,0,0)", opacity: 0 },
    delay: 500,
    config: config.wobbly,
  });
  const sittingAni = useSpring({
    to: [{ transform: "translate3d(0,0,0)", opacity: 1 }],
    from: { transform: "translate3d(-100%,0,0)", opacity: 0 },
    delay: 1000,
    config: config.wobbly,
  });
  const groupAni = useSpring({
    to: [{ transform: "translate3d(0,0,0)", opacity: 1 }],
    from: { transform: "translate3d(100%,0,0)", opacity: 0 },
    delay: 700,
    config: config.wobbly,
  });

  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 500));
      setLoading(!loading);
      postscribe(
        "#mydiv",
        "<script src='https://www.workable.com/assets/embed.js' type='text/javascript'></script>"
      );
      postscribe(
        "#whr_embed_hook",
        "<script type='text/javascript'>whr_embed(475114, {detail: 'titles', base: 'jobs', zoom: 'city', grouping: 'locations'});</script>",
        {
          // beforeWriteToken: function () {
          //   const job = document.querySelectorAll(".whr-item a");
          //   const location = document.querySelectorAll(".whr-group");

          //   job.forEach((item) => {
          //     item.setAttribute("target", "_blank");
          //   });

          //   location.forEach((item) => {
          //     if (item.innerHTML === "St. Louis") {
          //       item.innerHTML = "St. Louis, MO";
          //     }
          //     if (item.innerHTML === "Madison") {
          //       item.innerHTML = "Madison, WI";
          //     }
          //     if (item.innerHTML === "Middleton") {
          //       item.innerHTML = "Middleton, WI";
          //     }
          //     if (item.innerHTML === "Des Peres") {
          //       item.innerHTML = "Des Peres, MO";
          //     }
          //   });
          // },
          error: function (e) {
            console.log(e);
          },
        }
      );
    };

    loadData();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Loader
          type="Puff"
          color="#f3763cd2"
          height={100}
          width={100}
          timeout={3000} //3 secs
        />
      </div>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>CC3Solutions - Careers</title>
          <meta name="description" content="Join Our Team" />
        </Helmet>
        <div className={careers.careers_top}>
          {/* Opening Text */}
          <div className={`${careers.careers_gray}`}>
            <Container>
              <Row>
                <Col lg="12">
                  <div className={careers.joinUs}>
                    <h1
                      style={{
                        textAlign: "right",
                      }}
                    >
                      Come{" "}
                      <span
                        style={{
                          fontWeight: "600",
                          color: "rgba(243, 118, 60, 1)",
                        }}
                      >
                        Grow
                      </span>{" "}
                      With Us
                    </h1>
                  </div>
                  <div className={careers.shape}></div>

                  <div className={careers.openingDiv}>
                    <p className={careers.openingPara}>
                      At CC3, we believe in providing an environment that
                      nurtures growth from the{" "}
                      <span style={{ color: "rgba(243, 118, 60, 1)" }}>
                        moment
                      </span>{" "}
                      you come onboard.
                    </p>
                  </div>
                  <div className={careers.secondShape}></div>
                  <div className={careers.secondOpeningDiv}>
                    <p className={careers.openingSecondPara}>
                      If you are{" "}
                      <span style={{ color: "rgba(243, 118, 60, 1)" }}>
                        passionate
                      </span>{" "}
                      about building a career and growing your skillset within
                      the technology industry, CC3 is the place for you.
                    </p>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <HashLink
                      style={{ textDecoration: "none", border: "none" }}
                      smooth
                      to="/careers#jobs"
                      className={`btn mb-5 ${careers.hashBtn}`}
                    >
                      View Job Openings
                    </HashLink>
                  </div>
                </Col>
              </Row>
            </Container>
            <div className={careers.topCarousel}>
              {/* Carousel */}
              <Container className={careers.container}>
                <Swiper
                  modules={[
                    Navigation,
                    Pagination,
                    Autoplay,
                    Scrollbar,
                    EffectCards,
                  ]}
                  slidesPerView={1}
                  spaceBetween={50}
                  navigation
                  autoplay
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: true }}
                  effect="cards"
                >
                  <SwiperSlide>
                    <img src={awardsPic} alt="awards" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={chiefsPic} alt="chiefs" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={boardRoom} alt="boardroom" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={fallPumpkins} alt="fall pumpkins" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={office02} alt="office" />
                  </SwiperSlide>
                </Swiper>
              </Container>
            </div>
            <Container fluid={true} className={careers.containerFluid}>
              <div className={careers.culture}>
                <Row>
                  <Col lg="12">
                    <div className={careers.cultureOpening}>
                      <h1 style={{ fontSize: "7rem" }}>
                        Our{" "}
                        <span
                          style={{
                            fontWeight: "600",
                            color: "rgba(243, 118, 60, 1)",
                          }}
                        >
                          Culture
                        </span>
                      </h1>
                    </div>
                  </Col>
                  <Col lg="8">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div className={careers.cultureShape}></div>
                    </div>
                    <div className={careers.cultureDiv}>
                      <p className={careers.culturePara}>
                        <span style={{ color: "white", fontSize: "5rem" }}>
                          Transparent. Accepting. Adaptable.{" "}
                        </span>
                        <br />
                        <br />
                        Those are just three things we try to live by everyday
                        here at CC3. We are transparent in our communication
                        with every single employee from the top down.
                        <br />
                        <br />
                        We are one team and operate as such, allowing everyone
                        to voice their thoughts, collaborate, and improve the
                        organization day after day. Here at CC3, we accept all
                        and aim to continuously adapt to the ever-changing
                        workplace & tech industry around us.{" "}
                        <span
                          style={{
                            fontSize: "4rem",
                            textShadow: "-4px 1px 3px rgb(133, 143, 155)",
                            color: "rgb(243, 118, 60)",
                          }}
                        >
                          Let's grow together!
                        </span>
                      </p>
                    </div>
                  </Col>
                  <Col lg="4">
                    <Gallery photos={photos} direction={"row"} margin={4} />
                  </Col>
                </Row>
              </div>
            </Container>
            <div className={careers.benefits}>
              <Container>
                <Row>
                  <Col>
                    <div className={careers.benefitsDiv}>
                      <h1>
                        <span
                          style={{
                            fontWeight: "600",
                            color: "white",
                          }}
                        >
                          Our
                        </span>{" "}
                        Benefits
                      </h1>
                      <div className={careers.benefitsShape}></div>
                      <h3 className={careers.benefitsTagLine}>
                        Working with us has some perks...
                      </h3>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <div
                      className={`${careers.benefitsInfo} ${careers.benefitsBorderOne}`}
                    >
                      <h4>Family First Company</h4>
                      <p>
                        When you join the CC3 team, you are treated as if you
                        are family. Many of us have families of our own to care
                        and provide for. Supporting each other and making sure
                        everyone has the tools to succeed and provide for the
                        ones they love is extremely important to us. Life
                        happens and that’s why we provide paid time off, sick
                        leaves, and all other medical benefits the moment you
                        come onboard!
                      </p>
                    </div>
                  </Col>
                  <Col lg="12">
                    <div
                      className={`${careers.benefitsInfo} ${careers.benefitsBorderTwo}`}
                    >
                      <h4>Lucrative Compensation</h4>
                      <p>
                        Besides our competitive base salary that we provide to
                        all positions that we can. We have recently restructured
                        our commission structure to give our account managers
                        nearly 70 cents to the dollar of what they sell! With
                        regular performance checks for all our employees. Those
                        that perform, will be rewarded. We find this takes the
                        stress out of selling and makes it enjoyable for all.
                      </p>
                    </div>
                  </Col>
                  <Col lg="12">
                    <div
                      className={`${careers.benefitsInfo} ${careers.benefitsBorderOne}`}
                    >
                      <h4>Work hard, Play hard</h4>
                      <p>
                        Why can’t the workplace also be somewhere you have fun?!
                        Our competitive and energetic atmosphere allows for you
                        to be yourself while getting the job done. Take a break
                        and hit the putting green in our office or toss a ball
                        around with a co-worker. Earn a bonus to attend a
                        sporting event, vacation with your family, or eat a
                        company paid lunch and get to know the people you are
                        working with. Oh, and did I mention the kegerator in the
                        office open after 4:00 PM?! We are always finding new
                        ways to give you your time back and make sure you enjoy
                        coming to work every single day.
                      </p>
                    </div>
                  </Col>
                  <Col lg="12" className="col-lg-auto">
                    <div
                      className={`${careers.benefitsInfo} ${careers.benefitsBorderTwo}`}
                    >
                      <h4>401k Company Match</h4>
                      <p>
                        Always be thinking of your future! Your goals, family,
                        and life after CC3 matters. That’s why we match 3% of
                        your contribution towards your 401k!
                      </p>
                    </div>
                  </Col>
                  <Col lg="12">
                    <div
                      className={`${careers.benefitsInfo} ${careers.benefitsBorderOne}`}
                    >
                      <h4>Your Voice Matters</h4>
                      <p>
                        Don’t be afraid to speak up about anything you think we
                        can be doing better. We are all one team! We pride
                        ourselves on being as transparent as possible with every
                        single person within our company. Our focus on the
                        individual, whether an employee, customer, or other
                        stakeholder, is what makes us who we are! To grow we
                        must be open and willing to accept change.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <Container fluid={true}>
              <div className={careers.reviewsDiv}>
                <div className={careers.reviews}>
                  <h1 style={{ fontSize: "6rem" }}>
                    Employee{" "}
                    <span
                      style={{ fontWeight: "600", color: "rgb(243, 118, 60)" }}
                    >
                      Reviews
                    </span>
                  </h1>
                </div>
                <Container>
                  <div className={careers.reviewsPara}>
                    <Swiper
                      modules={[
                        Navigation,
                        Pagination,
                        Autoplay,
                        Scrollbar,
                        EffectFlip,
                      ]}
                      slidesPerView={1}
                      autoplay={{ enabled: "true", delay: 4000 }}
                      pagination={{ clickable: true }}
                      effect={"flip"}
                      draggable={true}
                      speed={1000}
                    >
                      <SwiperSlide>
                        <Row>
                          <Col lg="4">
                            <img
                              src={liz}
                              className="img-fluid rounded-circle"
                              style={{ width: "300px", height: "300px" }}
                            />
                          </Col>
                          <Col lg="8">
                            <blockquote className="blockquote text-right">
                              <p className="font-weight-bolder">
                                <span
                                  style={{
                                    fontSize: "6rem",
                                    color: "rgba(243, 118, 60, 1)",
                                    lineHeight: "0",
                                  }}
                                >
                                  “
                                </span>
                                Supportive leadership team, excellent benefits,
                                ability to grow within the company based on
                                performance. If you give your best, they will do
                                the same back.”
                              </p>
                              <div className="blockquote-footer font-italic">
                                Liz Wiele, Mobility Sales Executive
                              </div>
                            </blockquote>
                          </Col>
                        </Row>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Row>
                          <Col lg="4">
                            <img
                              src={clint}
                              className="img-fluid rounded-circle"
                              style={{ width: "300px", height: "300px" }}
                            />
                          </Col>
                          <Col lg="8">
                            <blockquote className="blockquote text-right">
                              <p className="font-weight-bolder">
                                <span
                                  style={{
                                    fontSize: "6rem",
                                    color: "rgba(243, 118, 60, 1)",
                                    lineHeight: "0",
                                  }}
                                >
                                  “
                                </span>
                                Family first company, high level of
                                transparency, growing company, and full autonomy
                                in my work.”
                              </p>
                              <div className="blockquote-footer font-italic">
                                Clint Shank, Director of Enterprise Sales
                              </div>
                            </blockquote>
                          </Col>
                        </Row>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Row>
                          <Col lg="4">
                            <img
                              src={rachel}
                              className="img-fluid rounded-circle"
                              style={{
                                width: "300px",
                                height: "300px",
                                objectPosition: "0 -30px",
                              }}
                            />
                          </Col>
                          <Col lg="8">
                            <blockquote className="blockquote text-right">
                              <p
                                className="font-weight-bolder"
                                style={{ fontSize: "3rem" }}
                              >
                                <span
                                  style={{
                                    fontSize: "6rem",
                                    color: "rgba(243, 118, 60, 1)",
                                    lineHeight: "0",
                                  }}
                                >
                                  {" "}
                                  &ldquo;
                                </span>
                                Awesome company culture & team environment. They
                                definitely invest a lot into their employees and
                                make the office environment enjoyable to work
                                in. Full kitchen of snacks/beverages, breaks
                                allowed with no issues as long as you are doing
                                your job, team collaboration & aid. &rdquo;
                              </p>

                              <div className="blockquote-footer font-italic">
                                Rachel Klaus, Director of Mobility
                              </div>
                            </blockquote>
                          </Col>
                        </Row>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </Container>
              </div>
            </Container>
            <Container>
              <Row>
                <Col lg="8" className="offset-lg-2" id="jobs">
                  <div className={`text-center mt-5`}>
                    {/* <animated.div
                        style={groupAni}
                        className={`${careers.ani_group_02}`}
                      >
                        <img
                          className={careers.img_move}
                          src={groupPic}
                          alt="team"
                        />
                      </animated.div>
                      <animated.div
                        style={tableAni}
                        className={`${careers.ani_group_01}`}
                      >
                        <img
                          className={careers.img_move}
                          src={tablePic}
                          alt="table"
                        />
                      </animated.div>
                      <animated.div
                        style={sittingAni}
                        className={`${careers.ani_group_03}`}
                        alt="group"
                      >
                        <img
                          className={careers.img_move}
                          src={sittingPic}
                          alt="sitting"
                        />
                      </animated.div> */}
                    <img
                      src={tableMeeting}
                      alt="tableMeeting"
                      className="img-fluid"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                <Col lg="12" className="text-center">
                  <div className={careers.joinUs}>
                    <h1
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Join{" "}
                      <span
                        style={{
                          fontWeight: "600",
                          color: "rgba(243, 118, 60, 1)",
                        }}
                      >
                        Our
                      </span>{" "}
                      Team
                    </h1>
                    <p
                      className="lead mt-5"
                      style={{
                        fontSize: "3rem",
                        fontFamily: "'Anton', sans-serif",
                      }}
                    >
                      Current Opportunities
                    </p>
                  </div>
                  <div id="mydiv"></div>
                  <div id="whr_embed_hook" className={careers.embed_hook}></div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
};

export default Careers;
