import {LogLevel} from "@azure/msal-browser";

// Dev Server - https://localhost:5000/contact
// Prod Server Live Dev - https://gtgholdings.com/contact
// Prod Server - https://cc3solutions.com/contact

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: process.env.REACT_APP_AUTHORITY,
        redirectUri: "https://localhost:5000/contact"
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {		
                    case LogLevel.Error:		
                        console.error(message);		
                        return;		
                    case LogLevel.Info:		
                        console.info(message);		
                        return;		
                    case LogLevel.Verbose:		
                        console.debug(message);		
                        return;		
                    case LogLevel.Warning:		
                        console.warn(message);		
                        return;	
                    default:
                        return;	
                }	
            }	
        }	
    }
};

export const loginRequest = {
    scopes: ["User.Read","Mail.Send","Mail.Send.Shared"],
    forceRefresh: true
}

export const graphConfig = {
    graphMeSendMail: "https://graph.microsoft.com/v1.0/users/7b10efe7-2b9b-4ed1-be92-3ef4336de178/sendMail"
};


{/* <Col sm="12">
                <animated.div style={formProps}>
                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      email: "",
                      phoneNumber: "",
                      businessName: "",
                      address: "",
                      message: "",
                    }}
                    validationSchema={schema}
                    onSubmit={(values, actions) => {
                      instance
                        .ssoSilent({
                          loginHint: "chris.rasch@cc3solutions.com",
                          scopes: loginRequest.scopes,
                        })
                        .then((response) => {
                          console.log(response)
                          actions.setSubmitting(true);
                          callMsGraph(
                            response.accessToken,
                            values.firstName,
                            values.lastName,
                            values.email,
                            values.message,
                            values.phoneNumber,
                            values.businessName,
                            values.address
                          )
                            .then((response) => {
                              
                              actions.setSubmitting(false);
                              setSuccess(true);
                              setFormMessage(
                                "Thanks For Reaching Out! We Will Get Back To You Shortly!"
                              );
                            })
                            .catch((e) => setFormMessage(e));
                        })
                        .catch((e) => {
                          if (e) {
                            console.log(e)
                            setFormMessage(e);
                          }
                        });
                    }}
                  >
                    {({
                      isSubmitting,
                      handleSubmit,
                      handleChange,
                      errors,
                      isValid,
                      values,
                      touched,
                    }) => {
                      if (!isSubmitting && success) {
                        return (
                          <>
                            <div className="text-center">
                              <h1>
                                {formMessage}
                              </h1>
                            </div>
                          </>
                        );
                      }
                      if (isSubmitting) {
                        return (
                          <>
                            <div className="text-center">
                              <Spinner
                                style={{ width: "6rem", height: "6rem" }}
                                type="grow"
                                color="primary"
                              />
                            </div>
                          </>
                        );
                      } else {
                        return (
                          <Form onSubmit={handleSubmit}>
                            <Row form>
                              <Col md={6}>
                                <FormGroup>
                                  <Input
                                    type="text"
                                    name="firstName"
                                    placeholder="First Name"
                                    bsSize="lg"
                                    onChange={handleChange}
                                    value={values.firstName}
                                    className={
                                      !touched.firstName && isValid
                                        ? "border-secondary"
                                        : errors.firstName
                                        ? "border-danger"
                                        : "border-success"
                                    }
                                  />
                                  {errors.firstName && (
                                    <div className="text-danger">
                                      {errors.firstName}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Input
                                    type="text"
                                    name="lastName"
                                    placeholder="Last Name"
                                    bsSize="lg"
                                    onChange={handleChange}
                                    value={values.lastName}
                                    className={
                                      !touched.lastName && isValid
                                        ? "border-secondary"
                                        : errors.lastName
                                        ? "border-danger"
                                        : "border-success"
                                    }
                                  />
                                  {errors.lastName && (
                                    <div className="text-danger">
                                      {errors.lastName}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Input
                                    type="email"
                                    name="email"
                                    placeholder="Email Address"
                                    bsSize="lg"
                                    onChange={handleChange}
                                    value={values.email}
                                    className={
                                      !touched.email && isValid
                                        ? "border-secondary"
                                        : errors.email
                                        ? "border-danger"
                                        : "border-success"
                                    }
                                  />
                                  {errors.email && (
                                    <div className="text-danger">
                                      {errors.email}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Input
                                    type="text"
                                    name="phoneNumber"
                                    placeholder="Phone Number"
                                    bsSize="lg"
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                    className={
                                      !touched.phoneNumber && isValid
                                        ? "border-secondary"
                                        : errors.phoneNumber
                                        ? "border-danger"
                                        : "border-success"
                                    }
                                  />
                                  {errors.phoneNumber && (
                                    <div className="text-danger">
                                      {errors.phoneNumber}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Input
                                    type="text"
                                    name="businessName"
                                    placeholder="Business Name"
                                    bsSize="lg"
                                    onChange={handleChange}
                                    value={values.businessName}
                                    className={
                                      !touched.businessName && isValid
                                        ? "border-secondary"
                                        : errors.businessName
                                        ? "border-danger"
                                        : "border-success"
                                    }
                                  />
                                  {errors.businessName && (
                                    <div className="text-danger">
                                      {errors.businessName}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Input
                                    type="text"
                                    name="address"
                                    placeholder="Address"
                                    bsSize="lg"
                                    onChange={handleChange}
                                    value={values.address}
                                    className={
                                      !touched.address && isValid
                                        ? "border-secondary"
                                        : errors.address
                                        ? "border-danger"
                                        : "border-success"
                                    }
                                  />
                                  {errors.address && (
                                    <div className="text-danger">
                                      {errors.address}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col md={12}>
                                <FormGroup>
                                  <Input
                                    type="textarea"
                                    name="message"
                                    placeholder="Message"
                                    bsSize="lg"
                                    style={{ height: "200px" }}
                                    onChange={handleChange}
                                    value={values.message}
                                    className={
                                      !touched.message && isValid
                                        ? "border-secondary"
                                        : errors.message
                                        ? "border-danger"
                                        : "border-success"
                                    }
                                  />
                                  {errors.message && (
                                    <div className="text-danger">
                                      {errors.message}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="text-center">
                                <Button className="contactBtn" type="submit">
                                  Submit
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        );
                      }
                    }}
                  </Formik>
                </animated.div>
              </Col> */}
