import React, { Component } from "react";
import { Container, Row, Col, Navbar, NavItem, NavLink, Nav } from "reactstrap";
import { Link } from "react-router-dom";
import bestPlaceImg from "../images/TWP_St_Louis_2021_AW.png";

// Images
import cc3Logo from "../images/CC3_white.png";
import footerImg from "../images/footer_img.png";

export class Footer extends Component {
  static displayName = Footer.name;
  render() {
    return (
      <Container fluid="true">
        <footer>
          <div className="footer_split footer_lg_space">
            <div className="flex justify-center items-center">
              <Row>
                <Col md="12">
                  <div className="text-center mx-auto footer_best_place">
                    {/* <img className="img-fluid" src={footerImg} alt="footer" /> */}
                    <img
                      className="img-fluid footer_best_place_img"
                      src={bestPlaceImg}
                      alt="footer"
                    />
                  </div>
                </Col>
                <Col md="12">
                  <div className="text-center mt-5">
                    <h3>Make The Change Today!</h3>
                    <p>Sign up today for a free analysis</p>
                    <div className="hero_action text-center">
                      <Link to="/contact" className="btn btn_orange">
                        Get Started Now!
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="footer_split footer_sm_space">
            <div className="container footer_seperate justify-center items-center">
              <Row>
                {/* <Col sm="12" className="px-0">
                  <h4 className="text-center solutionsFooterTitle">
                    Solutions
                  </h4>
                  <ul className="d-flex justify-content-around footerSubMenu">
                    <li>
                      <HashLink
                        smooth
                        to="/#network"
                        scroll={(el) =>
                          el.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          })
                        }
                      >
                        Network
                      </HashLink>
                    </li>
                    <li>
                      <HashLink
                        smooth
                        to="/#hybridit"
                        scroll={(el) =>
                          el.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          })
                        }
                      >
                        Hybrid IT
                      </HashLink>
                    </li>
                    <li>
                      <HashLink
                        smooth
                        to="/#voice"
                        scroll={(el) =>
                          el.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          })
                        }
                      >
                        Voice
                      </HashLink>
                    </li>
                    <li>
                      <HashLink
                        smooth
                        to="/#mobility"
                        scroll={(el) =>
                          el.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          })
                        }
                      >
                        Mobility
                      </HashLink>
                    </li>
                    <li>
                      <HashLink
                        smooth
                        to="/#security"
                        scroll={(el) =>
                          el.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          })
                        }
                      >
                        Security
                      </HashLink>
                    </li>
                  </ul>
                </Col> */}
              </Row>
              <Navbar light>
                <Nav className="mr-auto bottomNav footer_half" navbar>
                  <NavItem>
                    <NavLink tag={Link} to="/about">
                      About
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/careers">
                      Careers
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/partners">
                      Partners
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/contact">
                      Contact
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink tag={Link} to="/service-areas">
                      Service Areas
                    </NavLink>
                  </NavItem> */}
                </Nav>
              </Navbar>
            </div>
          </div>
          <div className="footer_split footer_bottom">
            <Container>
              <div className="d-flex flex-column flex-end">
                <h4 className="text-left mr-3">Follow Us!</h4>
                <a
                  href="https://www.linkedin.com/company/cc3solutions"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin fa-3x"></i>
                </a>
              </div>
            </Container>
            <div className="d-flex justify-content-between container items-center">
              <div>
                <div>&copy;{new Date().getFullYear()} - CC3 Solutions, LLC</div>
                <div>
                  <Link to="/privacy">Privacy Policy</Link>
                </div>
                <div>
                  <Link to="/terms">Terms of Use</Link>
                </div>
              </div>
              <div className="footer_right">
                <img className="img-fluid" src={cc3Logo} alt="logo" />
              </div>
            </div>
          </div>
        </footer>
      </Container>
    );
  }
}
