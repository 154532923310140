import React from "react";

const Sourcing = () => {
  return (
    <>
      <div style={{ marginTop: "11rem", textAlign: "center" }}>
        <h1>Sourcing Solutions</h1>
      </div>
    </>
  );
};

export default Sourcing;
