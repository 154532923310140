import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Container,
  Card,
  CardImg,
  CardText,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  UncontrolledCollapse,
} from "reactstrap";
// import { useSpring, animated, config } from "react-spring";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReactCardFlip from "react-card-flip";

//react-spring
import { useSpring, animated, config } from "react-spring";

// Images
import customerImg from "../images/customer_img.png";

import networkImg from "../images/networks_FIXED.jpg";
import itImg from "../images/it.jpg";
import securityImg from "../images/security.jpg";
import mobileImg from "../images/phone.jpg";
import talkImg from "../images/talk.jpg";
import warehouseImg from "../images/warehouse_FIXED.jpg";
import gadgetBuyBackImg from "../images/logo_fixed.png";
import sourcingImg from "../images/sourcing_FIXED.jpg";
import gadgetlabImg from "../images/gadgetlabstore_FIXED.jpg";
import bestPlaceImg from "../images/TWP_St_Louis_2021_AW.png";

const Home = () => {

  // Init State
  const [isFlipped, setIsFlipped] = useState(false);
  const [isItFlipped, setIsItFlipped] = useState(false);
  const [isMobFlipped, setIsMobFlipped] = useState(false);
  const [isVoiceFlipped, setIsVoiceFlipped] = useState(false);
  const [isSecurityFlipped, setIsSecurityFlipped] = useState(false);
  const [isWarehouseFlipped, setIsWarehouseFlipped] = useState(false);
  const [isSourcingFlipped, setIsSourcingFlipped] = useState(false);
  const [cardScroll, setCardScroll] = useState(0);

  // Animated Props
  const props = useSpring({
    to: { opacity: 1, transform: "translate(0, 0)" },
    from: { opacity: 0, transform: "translate(-100%,0)" },
    config: config.gentle,
  });
  const rightProps = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1000,
    config: config.molasses,
  });


  // Arrays
  const items = [];

  for (var i = 1; i < 8; i++) {
    if (i % 2) {
      items.push(
        <i
          key={i}
          className={`fas fa-angle-double-right fa-${i}x`}
          style={{ color: "white" }}
        ></i>
      );
    } else {
      items.push(
        <i
          key={i}
          className={`fas fa-angle-double-right fa-${i}x`}
          style={{ color: "#f3753c" }}
        ></i>
      );
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setCardScroll(window.scrollY)
    });
  });

  return (
    <>
      <div className="hero">
        <Helmet>
          <title>CC3Solutions - Home</title>
          <meta
            name="description"
            content="Technology Solutions,The Right Way. Secure, reliable solutions for any sized business"
          />
          <meta
            name="keywords"
            content="cc3solutions, gtgholdings, cc3, att service provider, gtg holdings, cc3 solutions"
          />
        </Helmet>
        <Container fluid={true}>
          <div className="hero_text flex items-center justify-left">
            <Row>
              <Col sm="12">
                <div className="techTop">
                  <animated.div style={props}>
                    <h1>
                      Technology Solutions...
                      <br />
                    </h1>
                  </animated.div>
                  <animated.div style={rightProps}>
                    <div className="d-flex align-items-center rightWay">
                      {items}
                      <h1>
                        The{" "}
                        <span
                          style={{
                            color: "#f3753c",
                            background: "rgba(255,255,255,0.5)",
                            padding: "0 1rem",
                            fontWeight: "500",
                          }}
                        >
                          Right
                        </span>{" "}
                        Way
                      </h1>
                    </div>
                    {/*<div className="d-flex align-items-center">*/}
                    {/*    <p style={{fontWeight: "600", fontSize: "1.5rem"}}>*/}
                    {/*      <span className="tailoredStart">T</span>ailored*/}
                    {/*      telecommunications solutions for any business,{" "}*/}
                    {/*      <span className="without">without</span> the{" "}*/}
                    {/*      <span className="redTape">red tape</span>.*/}
                    {/*     </p>*/}
                    {/* </div>*/}
                    {/*<h4 class="d-flex align-items-center" style={{ textIndent: "0.5rem" }}>{items} <span style={{fontSize: "2.5rem", color: "white"}}>Enter CC3 Solutions</span></h4>*/}
                    <div className="hero_action d-flex align-items-center">
                      <Link to="/contact" className="btn btn_orange">
                        Get Started Now!
                      </Link>
                      <h3 className="chatLink">
                        or click
                        <a
                          id="myChat"
                          style={{ color: "white" }}
                          href="mailTo:info@cc3solutions.com"
                        >
                          here
                        </a>
                        to chat with us!
                      </h3>
                    </div>
                    {/* <div className="hero_customer">
                    <small>Over 5,000 Companies Guided</small>
                    <div className="customer_row">
                      <img
                        className="img-fluid"
                        src={customerRow}
                        alt="customer"
                      />
                    </div>
                  </div> */}
                  </animated.div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {/* <div className="section section_gray">
        <div className="cntr_txt items-center">
          <div className="cntr_text_wrap trusted">
            <h4>Trusted by industry leaders</h4>
            <div className="cnt_img body_customer">
              <img className="img-fluid" src={customer01} alt="customer1" />
              <img className="img-fluid" src={customer02} alt="customer2" />
              <img className="img-fluid" src={customer03} alt="customer3" />
            </div>
          </div>
        </div>
      </div> */}
      <div className="section">
        <div className="body_split">
          <div className="container flex justify-center items-center">
            <Row>
              <Col sm="12" md="6">
                <div className="body_half">
                  <div>
                    <div
                      className="or_txt bold"
                      style={{
                        fontSize: "3rem",
                        borderBottom: "2px solid slategray",
                        padding: "1rem 0",
                        textShadow: "4px 2px 3px rgba(112,128,144,0.5)",
                      }}
                    >
                      WHY CC3?
                    </div>
                    <h3>EFFORTLESS CUSTOMER EXPERIENCE</h3>
                  </div>
                  <div>
                    <p>
                      Relentless commitment to ensuring satisfaction throughout
                      each phase of our engagement.
                    </p>
                  </div>
                </div>
              </Col>
              <Col sm="12" md="6">
                <div className="body_half text-center">
                  <img className="img-fluid" src={customerImg} alt="customer" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="cntr_txt flex justify-center items-center">
          <div className="bullet_count">
            <Row>
              <Col>
                <div className="bullet_wrap">
                  <div className="bullet_num_one bl_txt  bold">1,500+</div>
                  <div className="count_txt_one">
                    New Internet Circuits Installed Each Year
                  </div>
                </div>
              </Col>
              <Col>
                <div className="bullet_wrap bullet_middle">
                  <div className="bullet_num_two bl_txt  bold">24,000+</div>
                  <div className="count_txt_two">
                    Mobile Devices Ordered Each Year
                  </div>
                </div>
              </Col>
              <Col>
                <div className="bullet_wrap">
                  <div className="bullet_num_three bl_txt bold">Thousands</div>
                  <div className="count_txt_three">Of Hours Saved</div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="section section_gray body_split card_wrapper">
        <div className={cardScroll >= 700 ? "animate__animated animate__bounceInLeft container text-center" : "animate__animated animate__bounceOutLeft container text-center"}>
          <h3
            className="or_txt bold"
            id="solutions"
            style={{
              borderBottom: "2px solid slategray",
              padding: "2rem 0",
              textShadow: "4px 2px 5px rgba(112,128,144,0.5)",
            }}
          >
            Solutions
          </h3>
          <p>Secure, reliable solutions for any sized business</p>
          <Row style={{overflow: "hidden"}}>
            <Col sm="12" md="4" className={cardScroll >= 900 ? "animate__animated animate__bounceInLeft mb-3" : "animate__animated animate__bounceOutLeft mb-3"}>
              <ReactCardFlip
                isFlipped={isFlipped}
                containerStyle={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "447px",
                }}
                
              >
                <Card
                  className="text-center grid_card h-100"
                  id="network"
                  onClick={() => setIsFlipped(!isFlipped)}
                  style={{boxShadow: "0 5px 10px slategray"}}
                >
                  <CardImg
                    top
                    width="100%"
                    height="153px"
                    src={networkImg}
                    alt="card image"
                    style={{
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                    }}
                  />
                  <CardBody>
                    <CardTitle className="">
                      <h4>Network</h4>
                    </CardTitle>
                    <CardText>
                      <small>
                        The public internet has become the corporate network.
                        From shared to dedicated, let our team help determine
                        what’s best for your business
                      </small>
                      <br />
                      <br />
                    </CardText>
                  </CardBody>
                  <CardFooter>
                    <button className="btn btn-outline-secondary btn-sm">
                      Network Solutions
                    </button>
                  </CardFooter>
                </Card>
                <Card
                  className="text-center grid_card h-100"
                  onClick={() => setIsFlipped(!isFlipped)}
                  style={{boxShadow: "0 5px 10px slategray"}}
                >
                  <CardImg
                    top
                    width="100%"
                    height="153px"
                    src={networkImg}
                    alt="card image"
                    style={{
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                    }}
                  />
                  <CardBody>
                    <CardTitle className="">
                      <h4>Network Solutions</h4>
                    </CardTitle>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <Link to="/network">SD-WAN</Link>
                      </li>
                      <li className="list-group-item">
                        <Link to="/network">Private Connectivity</Link>
                      </li>
                      <li className="list-group-item">
                        Dedicated Internet Access
                      </li>
                      <li className="list-group-item">
                        <Link to="/network">Broadband</Link>
                      </li>
                      <li className="list-group-item">
                        <Link to="/network">Fixed Wireless</Link>
                      </li>
                    </ul>
                  </CardBody>
                </Card>
              </ReactCardFlip>
            </Col>
            <Col sm="12" md="4" className={cardScroll >= 900 ? "animate__animated animate__bounceInUp mb-3" : "animate__animated animate__bounceOutDown mb-3"}>
              <ReactCardFlip
                isFlipped={isItFlipped}
                containerStyle={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Card
                  className="text-center grid_card h-100"
                  id="hybridit"
                  onClick={() => setIsItFlipped(!isItFlipped)}
                  style={{boxShadow: "0 5px 10px slategray"}}
                >
                  <CardImg
                    top
                    width="100%"
                    height="153px"
                    src={itImg}
                    alt="card image"
                    style={{
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                    }}
                  />
                  <CardBody>
                    <CardTitle className="">
                      <h4>Hybrid IT</h4>
                    </CardTitle>
                    <CardText>
                      <small>
                        We know that customers today are faced with a choice -
                        look to the cloud or tackle in house. Our teams are
                        experts at helping customers determine when and where to
                        deploy their resources.
                      </small>
                      <br />
                      <br />
                    </CardText>
                  </CardBody>
                  <CardFooter>
                    <button className="btn btn-outline-secondary btn-sm">
                      IT Solutions
                    </button>
                  </CardFooter>
                </Card>
                <Card
                  className="text-center grid_card h-100"
                  onClick={() => setIsItFlipped(!isItFlipped)}
                  style={{boxShadow: "0 5px 10px slategray"}}
                >
                  <CardImg
                    top
                    width="100%"
                    height="153px"
                    src={itImg}
                    alt="card image"
                    style={{
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                    }}
                  />
                  <CardBody>
                    <CardTitle className="">
                      <h4>IT Solutions</h4>
                    </CardTitle>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <Link to="/it">Cloud</Link>
                      </li>
                      <li className="list-group-item">Colocation & Hosting</li>
                      <li className="list-group-item">Backup</li>
                      <li className="list-group-item">Disaster Recovery</li>
                    </ul>
                  </CardBody>
                </Card>
              </ReactCardFlip>
            </Col>
            <Col sm="12" md="4" className={cardScroll >= 900 ? "animate__animated animate__bounceInRight mb-3" : "animate__animated animate__bounceOutRight mb-3"}>
              <ReactCardFlip
                isFlipped={isMobFlipped}
                containerStyle={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Card
                  className="text-center grid_card h-100"
                  id="mobility"
                  onClick={() => setIsMobFlipped(!isMobFlipped)}
                  style={{boxShadow: "0 5px 10px slategray"}}
                >
                  <CardImg
                    top
                    height="153px"
                    width="100%"
                    src={mobileImg}
                    alt="card image"
                    style={{
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                    }}
                  />
                  <CardBody>
                    <CardTitle className="">
                      <h4>Mobility</h4>
                    </CardTitle>
                    <CardText>
                      <small>
                        Mobile phone environments are ever-changing and end-user
                        requests are endless. Our teams manage the entire
                        mobility lifecycle from pre-sales all the way through
                        lifecycle management and beyond.
                      </small>
                      <br />
                      <br />
                    </CardText>
                  </CardBody>
                  <CardFooter>
                    <button className="btn btn-outline-secondary btn-sm">
                      Mobility Solutions
                    </button>
                  </CardFooter>
                </Card>
                <Card
                  className="text-center grid_card h-100"
                  onClick={() => setIsMobFlipped(!isMobFlipped)}
                  style={{boxShadow: "0 5px 10px slategray"}}
                >
                  <CardImg
                    top
                    width="100%"
                    height="153px"
                    src={mobileImg}
                    alt="card image"
                    style={{
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                    }}
                  />
                  <CardBody>
                    <CardTitle className="">
                      <h4>Mobility Solutions</h4>
                    </CardTitle>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        Consulting & Optimization
                      </li>
                      <li className="list-group-item">
                        Expense, Project, & Lifecycle Management
                      </li>
                      <li className="list-group-item">
                        <Link to="/mobility">Device Buyback</Link>
                      </li>
                      <li className="list-group-item">
                        Advanced Warranty & Exchange
                      </li>
                      <li className="list-group-item">Equipment Sourcing</li>
                    </ul>
                  </CardBody>
                </Card>
              </ReactCardFlip>
            </Col>
            <Col sm="12" md="4" className={cardScroll >= 1500 ? "animate__animated animate__bounceInLeft mb-3" : "animate__animated animate__bounceOutLeft mb-3"}>
              <ReactCardFlip
                isFlipped={isVoiceFlipped}
                containerStyle={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Card
                  className="text-center grid_card h-100"
                  id="voice"
                  onClick={() => setIsVoiceFlipped(!isVoiceFlipped)}
                  style={{boxShadow: "0 5px 10px slategray"}}
                >
                  <CardImg
                    top
                    width="100%"
                    height="153px"
                    src={talkImg}
                    alt="card image"
                    style={{
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                    }}
                  />
                  <CardBody>
                    <CardTitle className="">
                      <h4>Voice</h4>
                    </CardTitle>
                    <CardText>
                      <small>
                        Communication and collaboration are key in today’s
                        business environment. We can help you connect your teams
                        working from anywhere, anytime.
                      </small>
                      <br />
                      <br />
                    </CardText>
                  </CardBody>
                  <CardFooter>
                    <button className="btn btn-outline-secondary btn-sm">
                      Voice Solutions
                    </button>
                  </CardFooter>
                </Card>
                <Card
                  className="text-center grid_card h-100"
                  onClick={() => setIsVoiceFlipped(!isVoiceFlipped)}
                  style={{boxShadow: "0 5px 10px slategray"}}
                >
                  <CardImg
                    top
                    width="100%"
                    height="153px"
                    src={talkImg}
                    alt="card image"
                    style={{
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                    }}
                  />
                  <CardBody>
                    <CardTitle className="">
                      <h4>Voice Solutions</h4>
                    </CardTitle>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <Link to="/voice">UCaaS</Link>
                      </li>
                      <li className="list-group-item">
                        <Link to="/voice">SIP</Link>
                      </li>
                      <li className="list-group-item">Collaboration</li>
                      <li className="list-group-item">
                        <Link to="/voice">VoIP</Link>
                      </li>
                    </ul>
                  </CardBody>
                </Card>
              </ReactCardFlip>
            </Col>
            <Col sm="12" md="4" className={cardScroll >= 1500 ? "animate__animated animate__bounceInUp mb-3" : "animate__animated animate__bounceOutDown mb-3"}>
              <ReactCardFlip
                isFlipped={isSecurityFlipped}
                containerStyle={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Card
                  className="text-center grid_card h-100"
                  id="security"
                  onClick={() => setIsSecurityFlipped(!isSecurityFlipped)}
                  style={{boxShadow: "0 5px 10px slategray"}}
                >
                  <CardImg
                    top
                    width="100%"
                    height="153px"
                    src={securityImg}
                    alt="card image"
                    style={{
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                    }}
                  />
                  <CardBody>
                    <CardTitle className="">
                      <h4>Security</h4>
                    </CardTitle>
                    <CardText>
                      <small>
                        From end point security to comprehensive threat
                        management platforms, we have the expertise and
                        solutions to secure and manage your environment.
                      </small>
                      <br />
                      <br />
                    </CardText>
                  </CardBody>
                  <CardFooter>
                    <button className="btn btn-outline-secondary btn-sm">
                      Security Solutions
                    </button>
                  </CardFooter>
                </Card>
                <Card
                  className="text-center grid_card h-100"
                  onClick={() => setIsSecurityFlipped(!isSecurityFlipped)}
                  style={{boxShadow: "0 5px 10px slategray"}}
                >
                  <CardImg
                    top
                    width="100%"
                    height="153px"
                    src={securityImg}
                    alt="card image"
                    style={{
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                    }}
                  />
                  <CardBody>
                    <CardTitle className="">
                      <h4>Security Solutions</h4>
                    </CardTitle>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">Consulting</li>
                      <li className="list-group-item">DDoS</li>
                      <li className="list-group-item">Secure eMail Gateway</li>
                      <li className="list-group-item">
                        Managed Security Services
                      </li>
                      <li className="list-group-item">
                        Security Information & Event Management (SIEM)
                      </li>
                    </ul>
                  </CardBody>
                </Card>
              </ReactCardFlip>
            </Col>
            <Col sm="12" md="4" className={cardScroll >= 1500 ? "animate__animated animate__bounceInRight mb-3" : "animate__animated animate__bounceOutRight mb-3"}>
              <ReactCardFlip
                isFlipped={isWarehouseFlipped}
                containerStyle={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "519px",
                }}
              >
                <Card
                  className="text-center grid_card h-100"
                  id="warehouse"
                  onClick={() => setIsWarehouseFlipped(!isWarehouseFlipped)}
                  style={{boxShadow: "0 5px 10px slategray"}}
                >
                  <CardImg
                    top
                    width="100%"
                    height="153px"
                    src={warehouseImg}
                    alt="card image"
                    style={{
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                    }}
                  />
                  <CardBody>
                    <CardTitle className="">
                      <h4>Warehousing</h4>
                    </CardTitle>
                    <CardText>
                      <small>
                        Let our team do the heavy lifting for all things
                        pertaining to mobile equipment. We consistently provide
                        staging and kitting for customers for device roll-outs,
                        provide storage space for spare inventory and
                        accessories, and manage spare replacement pools. Our
                        team can solve any logistics or deployment needs any
                        business encounters!
                      </small>
                      <br />
                      <br />
                    </CardText>
                  </CardBody>
                  <CardFooter>
                    <button className="btn btn-outline-secondary btn-sm">
                      Warehousing Solutions
                    </button>
                  </CardFooter>
                </Card>
                <Card
                  className="text-center grid_card h-100"
                  onClick={() => setIsWarehouseFlipped(!isWarehouseFlipped)}
                  style={{boxShadow: "0 5px 10px slategray"}}
                >
                  <CardImg
                    top
                    width="100%"
                    height="153px"
                    src={warehouseImg}
                    alt="card image"
                    style={{
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                    }}
                  />

                  <CardTitle>
                    <h4>Warehousing Solutions</h4>
                  </CardTitle>
                  <CardBody>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <Link to="/warehousing">Staging & Kitting</Link>
                      </li>
                      <li className="list-group-item">
                        Storage Space for Spare Inventory and Accessories
                      </li>
                      <li className="list-group-item">
                        Manage Spare Replacement Pools
                      </li>
                    </ul>
                  </CardBody>
                </Card>
              </ReactCardFlip>
            </Col>
            <Col sm="12" md="4" className={cardScroll >= 1800 ? "animate__animated animate__bounceInLeft mb-3" : "animate__animated animate__bounceOutLeft mb-3"}>
              <ReactCardFlip
                isFlipped={isSourcingFlipped}
                containerStyle={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Card
                  className="text-center grid_card h-100"
                  id="sourcing"
                  onClick={() => setIsSourcingFlipped(!isSourcingFlipped)}
                  style={{boxShadow: "0 5px 10px slategray"}}
                >
                  <CardImg
                    top
                    width="100%"
                    height="153px"
                    src={sourcingImg}
                    alt="card image"
                    style={{
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                    }}
                  />
                  <CardBody>
                    <CardTitle className="">
                      <h4>Sourcing</h4>
                    </CardTitle>
                    <CardText>
                      <small>
                        We specialize in sourcing certified A-Stock mobile
                        devices, mobile hotspots, wireless routers, signal
                        boosters, and accessories to meet the needs of your
                        business. Our goal is to procure the equipment at or
                        below budget.
                      </small>
                      <br />
                      <br />
                    </CardText>
                  </CardBody>
                  <CardFooter>
                    <button className="btn btn-outline-secondary btn-sm">
                      Sourcing Solutions
                    </button>
                  </CardFooter>
                </Card>
                <Card
                  className="text-center grid_card h-100"
                  onClick={() => setIsSourcingFlipped(!isSourcingFlipped)}
                  style={{boxShadow: "0 5px 10px slategray"}}
                >
                  <CardImg
                    top
                    width="100%"
                    height="153px"
                    src={sourcingImg}
                    alt="card image"
                    style={{
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                    }}
                  />
                  <CardBody>
                    <CardTitle className="">
                      <h4>Sourcing Solutions</h4>
                    </CardTitle>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">Tablets</li>
                      <li className="list-group-item">Phones</li>
                      <li className="list-group-item">Mobile Hotspots</li>
                      <li className="list-group-item">Signal Boosters</li>
                      <li className="list-group-item">Accessories</li>
                    </ul>
                  </CardBody>
                </Card>
              </ReactCardFlip>
            </Col>
            <Col sm="12" md="4" className={cardScroll >= 1800 ? "animate__animated animate__bounceInUp mb-3" : "animate__animated animate__bounceOutDown mb-3"}>
              <Card
                className="text-center grid_card h-100"
                id="corporatebuyback"
                style={{boxShadow: "0 5px 10px slategray"}}
              >
                <CardImg
                  top
                  height="143px"
                  src={gadgetBuyBackImg}
                  alt="card image"
                  style={{
                    width: "60%",
                    margin: "0 auto",
                    paddingTop: "10px",
                  }}
                />
                <hr />
                <CardBody>
                  <CardTitle className="">
                    <h4>Corporate Buyback</h4>
                  </CardTitle>
                  <CardText>
                    <small>
                      As a trusted partner, we offer a simple and profitable
                      process for organizations looking to get the maximum
                      return on their end-of-life mobile devices. Our buyback
                      program includes a 100% guaranteed erasure service,
                      ensuring all data is completely erased from every device.
                    </small>
                    <br />
                    <br />
                  </CardText>
                </CardBody>
                <CardFooter>
                  <a
                    role="button"
                    className="btn"
                    style={{
                      color: "#f3753c",
                      border: "1px solid #f3753c",
                    }}
                    size="sm"
                    id="buybackToggler"
                    href="https://www.gadgetbuyback.com/"
                    target="_blank"
                  >
                    Visit Website
                  </a>
                </CardFooter>
              </Card>
            </Col>
            <Col sm="12" md="4" className={cardScroll >= 1800 ? "animate__animated animate__bounceInRight mb-3" : "animate__animated animate__bounceOutRight mb-3"}>
              <Card
                className="text-center grid_card h-100"
                id="corporaterepair"
                style={{boxShadow: "0 5px 10px slategray"}}
              >
                <CardImg
                  top
                  height="153px"
                  width="100%"
                  src={gadgetlabImg}
                  alt="card image"
                  style={{
                    borderTopRightRadius: "10px",
                    borderTopLeftRadius: "10px",
                  }}
                />
                <CardBody>
                  <CardTitle className="">
                    <h4>Corporate Repair</h4>
                  </CardTitle>
                  <CardText>
                    <small>
                      The mobile device has become as critical as the corporate
                      computer, providing access to email, applications, and
                      client communication. We understand end users cannot be
                      without their devices. Our corporate repair program
                      provides replacement equipment in advance, which minimizes
                      down-time and ensures end-users aren’t wasting time
                      waiting around for repairs.
                    </small>
                    <br />
                    <br />
                  </CardText>
                </CardBody>
                <CardFooter>
                  <a
                    className="btn"
                    role="button"
                    style={{
                      color: "#f3753c",
                      border: "1px solid #f3753c",
                    }}
                    size="sm"
                    id="repairToggler"
                    href="https://www.gadgetlabmobile.com/"
                    target="_blank"
                  >
                    Visit Website
                  </a>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Home;
