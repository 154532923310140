import React, { Suspense } from "react";
import { Route, HashRouter, BrowserRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import Layout from "./Layout";
import Home from "./Home";
import About from "./About";
import Contact from "./Contact";
import Partners from "./Partners";
import Careers from "./Careers";
import Privacy from "./Privacy";
import Terms from "./Terms";
import { Helmet } from "react-helmet";
import Tem from "./termsAndCond";
import SoftwareTC from "./softwareTerms";
import ServiceAreas from "./ServiceAreas";
import SaintLouis from "./SaintLouis";
import KansasCity from "./KansasCity";
import Chicago from "./Chicago";
import Indianapolis from "./Indianapolis";
import Network from "./Network";
import IT from "./IT";
import Mobility from "./Mobility";
import Voice from "./Voice";
import Security from "./Security";
import Warehousing from "./Warehousing";
import Sourcing from "./Sourcing";
import MSA from "./MasterTerms";


import "../custom.css";
import ScrollToTop from "./ScrollToTop";



const HomeLayout = () => {
    return (
        <Layout>
            <Helmet>
                <script type="application/ld+json">
                    {`
                            { 
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            "address": {
                                "type": "PostalAddress",
                                "addressLocality": "St. Louis, Missouri",
                                "postalCode": "63131",
                                "streetAddress": "13075 Manchester Road"
                                },
                            "brand": {
                                    "type": "Organization",
                                    "name": "AT&T"
                                    },
                            "name": "CC3 Solutions, LLC",
                            "description": "AT&T Solution Provider.",
                            "telephone": "1-877-344-3008",
                            "url": "https://cc3solutions.com",
                            "logo" : "https://cc3solutions.com/images/cc3_white.png"
                            }
                        `}
                </script>
                <script type="application/ld+json">
                    {`
                           { 
                            "@context": "http://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement":
                            [{
                            "type": "ListItem",
                            "position": 1,
                            "item":
                            {
                            "id": "https://cc3solutions.com",
                            "name": "CC3 Solutions, LLC - An AT&T Exclusive Solution Provider"
                            }
                            },{
                            "type": "ListItem",
                            "position": 2,
                            "item":
                            {
                            "id": "https://cc3solutions.com/about",
                            "name": "CC3 Solutions, LLC - About Us"
                            }
                            },{
                            "type": "ListItem",
                            "position": 3,
                            "item":
                            {
                            "id": "https://cc3solutions.com/careers",
                            "name": "CC3 Solutions, LLC - Careers"
                            }
                            },{
                            "type": "ListItem",
                            "position": 4,
                            "item":
                            {
                            "id": "https://cc3solutions.com/partners",
                            "name": "CC3 Solutions, LLC - Partners"
                            }
                            },{
                            "type": "ListItem",
                            "position": 5,
                            "item":
                            {
                            "id": "https://cc3solutions.com/contact",
                            "name": "CC3 Solutions, LLC - Contact Us"
                            }
                            }]
                            }
                        `}
                </script>
            </Helmet>
            <ScrollToTop />

            <Route exact path="/" component={Home} />
            <Route path="/about" exact={true} component={About} />
            <Route path="/contact" exact={true} component={Contact} />
            <Route path="/partners" exact={true} component={Partners} />
            <Route path="/careers" exact={true} component={Careers} />
            <Route path="/privacy" exact={true} component={Privacy} />
            <Route path="/terms" exact={true} component={Terms} />
            {/* <Route path='/service-areas' exact={true} component={ServiceAreas} />
                <Route path='/service-st-louis' exact={true} component={SaintLouis} />
                <Route path='/service-kansas-city' exact={true} component={KansasCity} />
                <Route path='/service-chicago' exact={true} component={Chicago} />
                <Route path='/service-indianapolis' exact={true} component={Indianapolis} /> */}
            <Route path="/tem-terms-and-conditions" exact={true} component={Tem} />
            <Route path="/Terms_and_Conditions.pdf" exact={true} component={SoftwareTC} />
            <Route path="/msa" exact={true} component={MSA} />
            <Route path="/network" exact={true} component={Network} />
            <Route path="/it" exact={true} component={IT} />
            <Route path="/mobility" exact={true} component={Mobility} />
            <Route path="/voice" exact={true} component={Voice} />
            <Route path="/security" exact={true} component={Security} />
            <Route path="/warehousing" exact={true} component={Warehousing} />
            <Route path="/sourcing" exact={true} component={Sourcing} />
        </Layout>
    );
};

export default HomeLayout;
