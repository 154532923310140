import React, { useState, useEffect } from "react";
import Services from "./Services";
import Loader from "react-loader-spinner";

import icImg from "../images/internetConnectivity.jpg";
import cloudImg from "../images/cloudServer.jpg";

const IT = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 500));

      setLoading(!loading);
    };
    loadData();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Loader
          type="Puff"
          color="#f3763cd2"
          height={100}
          width={100}
          timeout={3000} //3 secs
        />
      </div>
    );
  } else {
    return (
      <>
        <div style={{ marginTop: "11rem", textAlign: "center" }}>
          <Services
            autoPlay={3000}
            infinite={true}
            title="IT Solutions"
            borderColor="dodgerblue"
            slides={[
              {
                icon: "fa-cloud",
                service: "Cloud",
                description: `Leveraging cloud service providers has become a critical component to successful IT.
                Our teams are experts in finding the right balance of leveraging CSPs and maximizing existing investments in traditional infrastructure. We can guide you in finding the right XaaS partners and providing reliable, secure connectivity into your cloud environments.
                For customers looking for hosting, we’ve built relationships with over 300 data centers world-wide to ensure you get the right solution, in the right location, at the right price point.`,
                image: cloudImg,
              },
            ]}
          />
        </div>
      </>
    );
  }
};

export default IT;
