import React, { useState, useEffect } from "react";
import Services from "./Services";
import devicesImg from "../images/devices.jpg";
import Loader from "react-loader-spinner";

import voiceImg from "../images/voiceMic.jpg";
import soundWaveImg from "../images/soundWave.jpg";
import phoneLineImg from "../images/phoneLine.jpg";

const Voice = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 500));

      setLoading(!loading);
    };
    loadData();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Loader
          type="Puff"
          color="#f3763cd2"
          height={100}
          width={100}
          timeout={3000} //3 secs
        />
      </div>
    );
  } else {
    return (
      <>
        <div style={{ marginTop: "11rem", textAlign: "center" }}>
          <Services
            autoPlay={3000}
            infinite={true}
            bkSize="cover"
            borderColor="#b05d5d"
            title="Voice Solutions"
            slides={[
              {
                icon: "fa-phone-volume",
                service: "UCaaS",
                description: `With today’s workforce becoming more mobile and decentralized, its more important than ever before to have solutions that connect your people and allow them to collaborate. We can help determine the right conferencing solution leveraging video, voice and web sharing.`,
                image: voiceImg,
              },
              {
                icon: "fa-server",
                service: "SIP",
                description: `Move away from legacy services and start leveraging the power of Voice over IP (VOIP). Our teams can assist with a migration strategy and roadmap for local calling, long distance and toll-free services to ensure your company is optimizing spend and can scale for future growth.`,
                image: soundWaveImg,
              },
              {
                icon: "fa-phone-square",
                service: "VoIP",
                description: `Tired of managing old PBX’s and legacy phone equipment? Let our teams help guide you to a centralized voice model with hosted phone equipment!`,
                image: phoneLineImg,
              },
            ]}
          />
        </div>
      </>
    );
  }
};

export default Voice;
