import React, { useEffect } from "react";
import NavMenu from "./NavMenu";
import { Footer } from "./Footer";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
import postscribe from "postscribe";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

const Layout = (props) => {
  //   static displayName = Layout.name;
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("UA-113481679-1");
    ReactGA.pageview(location.pathname + location.search);

    window.Intercom("boot", {
      app_id: "j4srx9as",
      custom_launcher_selector: "#myChat",
    });

    // window.Intercom("boot", {
    //   app_id: "asmfadqw",
    // });
    // window.Intercom("update");
  }, [location]);

  return (
    <>
      <NavMenu />
      {props.children}
      <HashLink
        smooth
        to={`${location.pathname}#top`}
        style={{
          position: "fixed",
          bottom: "20px",
          right: 20,
          color: "rgba(243, 118, 60, 1)",
        }}
        className="text-center"
      >
        <i className="fas fa-hand-point-up fa-4x"></i>
      </HashLink>
      <Footer />
      {/* <div id="ga"></div>
      <div id="gaScript"></div> */}
    </>
  );
};

export default Layout;
