import React from "react";
import Services from "./Services";

import ethernetImg from "../images/ethernet.jpg";
import devicesImg from "../images/devices.jpg";

const Mobility = () => {
  return (
    <>
      <div style={{ marginTop: "11rem", textAlign: "center" }}>
        <Services
          autoPlay={3000}
          infinite={true}
          bkSize="cover"
          borderColor="#709c7c"
          title="Mobility Solutions"
          slides={[
            {
              icon: "fa-mobile-alt",
              service: "Device Buyback",
              description: `Struggling to accommodate your end users and their never-ending requests? Let us do it for you! We will buy your device regardless of condition and if it needs to be recycled, we follow all proper recycling protocols. We strive to create the best buyback experience for you through our top-tier customer support, aggressive offers, and free shipping to and from our facility`,
              image: devicesImg,
            }
          ]}
        />
      </div>
    </>
  );
};

export default Mobility;
