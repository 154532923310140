import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import United from "./United";
import HomeLayout from "./HomeLayout";

const Router = () => {
    return (
        <Switch>
            <Route exact path="/" component={HomeLayout} />
            <Route exact path="/about" component={HomeLayout} />
            <Route path="/contact" exact={true} component={HomeLayout} />
            <Route path="/partners" exact={true} component={HomeLayout} />
            <Route path="/careers" exact={true} component={HomeLayout} />
            <Route path="/privacy" exact={true} component={HomeLayout} />
            <Route path="/terms" exact={true} component={HomeLayout} />
            <Route path="/tem-terms-and-conditions" exact={true} component={HomeLayout} />
            <Route path="/Terms_and_Conditions.pdf" exact={true} component={HomeLayout} />
            <Route path="/msa" exact={true} component={HomeLayout} />
            <Route path="/network" exact={true} component={HomeLayout} />
            <Route path="/it" exact={true} component={HomeLayout} />
            <Route path="/mobility" exact={true} component={HomeLayout} />
            <Route path="/voice" exact={true} component={HomeLayout} />
            <Route path="/security" exact={true} component={HomeLayout} />
            <Route path="/warehousing" exact={true} component={HomeLayout} />
            <Route path="/sourcing" exact={true} component={HomeLayout} />
            <Route path="/united" component={United} />
        </Switch>
    );
};

export default Router;
