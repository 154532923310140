import React, { useState, useEffect } from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import Loader from "react-loader-spinner";

import { useTransition, animated, config } from "react-spring";
import { Helmet } from "react-helmet";

import partners from "../styles/partners.module.css";

// Logos
import accBusinessLogo from "../images/accBusiness.png";
import appleLogo from "../images/apple.png";
import arubaLogo from "../images/aruba.png";
import attLogo from "../images/att.png";
import akamaiLogo from "../images/akamai.png";
import cloudGenixLogo from "../images/cloudgenix.png";
import equinixLogo from "../images/equinix.png";
import fortinetLogo from "../images/fortinet.png";
import glsLogo from "../images/gls.png";
import merakiLogo from "../images/meraki.png";
import mitelLogo from "../images/mitel.png";
import paloaltoLogo from "../images/paloalto.png";
import qosNetworksLogo from "../images/qosNetworks.png";
import ringCentralLogo from "../images/ringCentral.png";
import samsungLogo from "../images/samsung.png";
import silverPeakLogo from "../images/silverpeak.png";
import veloCloudLogo from "../images/veloCloud.png";
import viptelaLogo from "../images/viptela.png";
import vxchangeLogo from "../images/vxchange.png";
import webexLogo from "../images/webEx.png";
import zoomLogo from "../images/zoom.png";
import zscalerLogo from "../images/zscaler.png";
import spectrumLogo from "../images/spectrum.png";

const Partners = () => {
  const [index, set] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 500));
      setLoading(!loading);
      const updateLogo = setInterval(
        () => set((state) => (state + 1) % 23),
        2000
      );
      return function cleanup() {
        clearInterval(updateLogo);
      };
    };
    loadData();
  }, []);

  const partnerArray = [
    { name: "AT&T", logo: attLogo },
    { name: "ACC Business", logo: accBusinessLogo },
    { name: "RingCentral", logo: ringCentralLogo },
    { name: "Mitel", logo: mitelLogo },
    { name: "Zoom", logo: zoomLogo },
    { name: "Cisco WebEx", logo: webexLogo },
    { name: "VeloCloud", logo: veloCloudLogo },
    { name: "Viptella", logo: viptelaLogo },
    { name: "Meraki", logo: merakiLogo },
    { name: "Fortinet", logo: fortinetLogo },
    { name: "Cloudgenix", logo: cloudGenixLogo },
    { name: "Silverpeak", logo: silverPeakLogo },
    { name: "Aruba", logo: arubaLogo },
    { name: "Palo Alto", logo: paloaltoLogo },
    { name: "Zscaler", logo: zscalerLogo },
    { name: "Akamai", logo: akamaiLogo },
    { name: "Vxchange", logo: vxchangeLogo },
    { name: "Equinix", logo: equinixLogo },
    { name: "QOS", logo: qosNetworksLogo },
    { name: "GLS", logo: glsLogo },
    { name: "Samsung", logo: samsungLogo },
    { name: "Apple", logo: appleLogo },
    { name: "Spectrum", logo: spectrumLogo },
  ];

  const transitions = useTransition(partnerArray[index], {
    from: { opacity: 0, transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0,0,0)" },
    leave: { opacity: 0, transform: "translate3d(-50%,0,0)" },
    config: config.molasses,
  });

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Loader
          type="Puff"
          color="#f3763cd2"
          height={100}
          width={100}
          timeout={3000} //3 secs
        />
      </div>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>CC3Solutions - Partners</title>
          <meta
            name="description"
            content="Delivering a best-in-class solution doesn’t come easily and often requires support and expertise from several companies."
          />
        </Helmet>
        <Container fluid="true">
          <div className={`${partners.partners_top_half}`}>
            <h1 style={{ textShadow: "4px 2px 5px rgba(112,128,144,0.5)" }}>
              Come
              <br />{" "}
              <span style={{ fontWeight: "600", marginLeft: "7rem" }}>
                Partner
              </span>{" "}
              With Us...
            </h1>

            <Row className={partners.partners_gray}>
              <Col lg="5">
                <div
                  className={`${partners.partners_bottom_half} d-flex flex-column justify-content-center`}
                >
                  <p className="m-3">
                    <span className="about_cap">D</span>elivering a
                    best-in-class solution doesn’t come easily and often
                    requires support and expertise from several companies.{" "}
                    <br />
                    <br />
                    <span className={partners.emphasize_cc3}>CC3</span> has an
                    ecosystem of partners ranging from Gartner Magic Quadrant
                    leaders to niche players. Our team has the <br />
                    <span className={partners.emphasize}>
                      {" "}
                      | technical knowledge |{" "}
                    </span>{" "}
                    and <br />
                    <span className={partners.emphasize}> | experience | </span>
                    to help weave them together for a{" "}
                    <span className={partners.emphasize}>
                      {" "}
                      | customized solution |{" "}
                    </span>{" "}
                    that fits your needs.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div className={partners.partners_mid_section}>
            <div
              className={`${partners.partners_lower_top} ${partners.partners_gray_reverse} d-flex flex-column`}
            >
              <h2>Network Partners</h2>
              <Container>
                <Row>
                  <Col lg="12">
                    {transitions((values, item) => {
                      return (
                        <animated.div
                          style={values}
                          className={partners.partner_slide}
                        >
                          <img
                            className="img-fluid rounded"
                            src={item.logo}
                            alt={`${item.name} logo`}
                          />
                          <h3>{item.name}</h3>
                        </animated.div>
                      );
                    })}
                  </Col>
                  {/* end col */}
                </Row>
                {/* end row */}
              </Container>
              {/* end container */}
            </div>
          </div>

          <ListGroup
            horizontal="md"
            className="d-flex justify-content-around text-center"
          >
            {partnerArray.map((partner, i) => {
              if (i <= 11) {
                return (
                  <ListGroupItem
                    key={i}
                    style={{ fontWeight: "600", border: "none" }}
                  >
                    {partner.name}
                  </ListGroupItem>
                );
              }
            })}
          </ListGroup>
          <ListGroup
            horizontal="md"
            className="d-flex justify-content-around text-center"
          >
            {partnerArray.map((partner, i) => {
              if (i > 11) {
                return (
                  <ListGroupItem
                    key={i}
                    style={{ fontWeight: "600", border: "none" }}
                  >
                    {partner.name}
                  </ListGroupItem>
                );
              }
            })}
          </ListGroup>
        </Container>
      </>
    );
  }
};

export default Partners;
