import React, { useState } from "react";

import pdf from "../pdf/software_terms_and_conditions.pdf"


const SoftwareTC = () => {
   
    return (
        <div className="text-center">
            <iframe width="100%" height="1000px" style={{paddingTop: "150px", border: "none"}} src={pdf} />
        </div>
        )
}

export default SoftwareTC;